import React from 'react'
import './Banner.scss';
import { Link } from 'react-router-dom';
import { bannerData } from './BannerCardData';
import { MdArrowRightAlt } from "react-icons/md";
import { FormattedMessage } from "react-intl"

const Banner = () => {
  return (
    <div className='banner'>
        <div className='bannerUp'>
            <div className='leftBg'>
                <div className='bgBanner1'></div>
                <div className='star'></div>
                <div className='magnet'></div>
                <div className='waves'></div>
                <div className='star3'></div>
            </div>
            <div className='bannerMiddle'>
                    <div className='bannerMiddleHeader'>
                        <p>
                        <FormattedMessage id="ban1"  />
                            <span className='redTextBanner'>
                            <FormattedMessage id="ban1-2"  />
                                </span>
                        </p>
                    </div>

                    <div className='bannerMiddleMiddle'>
                        <p>
                        <FormattedMessage id="ban2"  />
                            </p>
                    </div>

                    <div className='middleBanner-buttons'>
                        <div className='navbarButton1 button'>
                            <Link>
                            <FormattedMessage id="ban3"  />
                            </Link>
                        </div>
                        <div className='secondBannerButton button'>
                            <Link>
                            <FormattedMessage id="ban4"  />
                            </Link>
                        </div>
                    </div>
            </div>
            <div className='rightBg'>
                <div className='bgBanner2'></div>
                <div className='star2'></div>
                <div className='circle'></div>
                <div className='magnet2'></div>
                <div className='waves2'></div>
            </div>
        </div>

        <div className='bannerDown'>
            <div className='bannerCardsUp'>
                {
                    bannerData.map((c,index) => {
                        return(
                            <Link className='bannerCard' key={index}>
                                <div className='bannerCardh1 hover'></div>
                                <div className='bannerCardh2 hover'></div>
                                <div className='bannerCardh3 hover'></div>
                                <div className='bannerCardUp'>
                                    <div className='ciconBanner' style={{ content: `url(${c.icon})` }}></div>
                                    <p className='bannerCtitle'>{c.title}</p>
                                </div>
                                <div className='bannerCardDown'>
                                    <p className='bcDes'>{c.description}</p>
                                    <div className='learnMore hover'>
                                        <p>
                                        <FormattedMessage id="ban5-link"  />
                                            </p>
                                        <div className='arrowCircle'>
                                            <MdArrowRightAlt className='aciconar'/>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
            <div className='bannerDownButton button'>
                <Link>
                <FormattedMessage id="ban5-link2"  />
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Banner
