import React from 'react'
import './OurCenterContactUs.scss'
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhone } from "react-icons/fi";
import { FaRegComment } from "react-icons/fa";

const OurCenterContactUs = (props) => {
  return (
    <div className='OurCenterContactUs'>
        <div className="left">
            <div className="contactTextGroup">
                <div className="wwoUp">
                    <p>
                    Contact  
                    <span className="offerRed">&nbsp;Us</span>
                    </p>
                </div>

                <p>
                Reach out to our team anytime, for any question you may have.
                </p>
            </div>

            <div className="contactCards">
                <div className="card purpleCard">
                    <span className='leftCard location'>
                        <HiOutlineLocationMarker />
                    </span>
                    <div className="rightCard">
                        <span>
                        Location
                        </span>

                        <p>
                            {props.address}
                        </p>
                    </div>
                </div>

                <div className="card greenCard">
                    <span className='leftCard location'>
                        <FiPhone />
                    </span>
                    <div className="rightCard">
                        <span>
                        Give us a phone call:
                        </span>

                        <p>
                        {props.number}
                        </p>
                    </div>
                </div>

                <div className="card yellowCard">
                    <span className='leftCard location'>
                        <FaRegComment />
                    </span>
                    <div className="rightCard">
                        <span>
                        Contact us online:
                        </span>

                        <p>
                        Fill in the form
                        </p>
                    </div>
                </div>

            </div>
        </div>

        <div className="right">

            <div className="contact__yellowStar"></div>
            <div className="contact__greenStar"></div>
            <div className="contact__wave"></div>
            <div className="contact__magent"></div>


        </div>


    </div>
  )
}

export default OurCenterContactUs