import { FormattedMessage } from "react-intl"

export const shopTabs = [
    {
        city : <FormattedMessage id="tab1-1"  />
    },
    {
        city : <FormattedMessage id="shtdT1"  />
    },
    {
        city : <FormattedMessage id="shtdT2"  />
    },
    {
        city : <FormattedMessage id="shtdT3"  />
    },
    {
        city : <FormattedMessage id="shtdT4"  />
    },
    {
        city : <FormattedMessage id="shtdT5"  />
    }
]