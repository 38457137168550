import React from "react";
import "./DayCare.scss";
import Footer from "../../components/shared/Footer/Footer";
import Navbar from "../../components/shared/Navbar/Navbar";
import DayCareCard1 from "../../components/pages/DayCare/DayCareCard1/DayCareCard1";

import card1__1 from "../../assets/images/DayCare/card1__1.svg";
import card1__2 from "../../assets/images/DayCare/card1__2.svg";

import card2__1 from "../../assets/images/DayCare/card2__1.svg";
import card2__2 from "../../assets/images/DayCare/card2__2.svg";
import card2__3 from "../../assets/images/DayCare/card2__3.svg";
import card2__4 from "../../assets/images/DayCare/card2__4.svg";
import card2__5 from "../../assets/images/DayCare/card2__5.svg";
import card2__6 from "../../assets/images/DayCare/card2__6.svg";
import card2__7 from "../../assets/images/DayCare/card2__7.svg";
import card2__8 from "../../assets/images/DayCare/card2__8.svg";

import card3__1 from "../../assets/images/DayCare/bigCard1.webp";
import card3__2 from "../../assets/images/DayCare/bigCard2.webp";

import card3__1__icon1 from "../../assets/images/DayCare/DayWave.svg";
import card3__1__icon2 from "../../assets/images/DayCare/DayStar.svg";
import card3__1__icon3 from "../../assets/images/DayCare/DayMagnet.svg";

import card3__2__icon1 from "../../assets/images/DayCare/Day2Circle.svg";
import card3__2__icon2 from "../../assets/images/DayCare/Day2Star.svg";
import card3__2__icon3 from "../../assets/images/DayCare/Day2Magnet.svg";


import DayCareCard2 from "../../components/pages/DayCare/DayCareCard2/DayCareCard2";
import DayCareCard3 from "../../components/pages/DayCare/DayCareCard3/DayCareCard3";

const DayCare = () => {
  return (
    <div className="DayCare">
      <Navbar />
      <div className="WeekEndStay__inside DayCare__inside">
        <div className="WeekEndStay__1 DayCare__1">
          <div className="WeekEndStay__1__filter"></div>
          <h1 className="ourServiceTitleBg">Kindergarten & Daycare</h1>

          <p className="ourServiceText">
            Every day is an adventure filled with learning and fun!
          </p>
        </div>

        <div className="DayCarePadding">
          <DayCareCard1
            bgImage={card1__1}
            text1={"Kindergarten"}
            text2={"Ages 9 months - 6 years"}
            text3={
              "Welcome to KosovaKids' Kindergarten, where we lay the foundation for lifelong learning in a nurturing and stimulating environment. Tailored for young learners aged 9 months to 6 years, our program is designed to spark curiosity, encourage creativity, and foster social skills through a blend of play-based learning and structured educational activities."
            }
          />

          <div className="DayCare__2">
            <DayCareCard2
              bgImage={card2__1}
              title={"Our philosophy"}
              text={
                "We believe in the holistic development of children, integrating physical, cognitive, emotional, and social growth into every aspect of our curriculum. Our approach is child-centered, focusing on developing each child's individual strengths and interests within a supportive community setting."
              }
            />

            <DayCareCard2
              bgImage={card2__2}
              title={"Engaging with families"}
              text={
                "We value the strong bond between school and home, offering regular updates, workshops, and events that invite families to engage with their child's learning journey. Our goal is to build a supportive and inclusive community that benefits every child and family."
              }
            />

            <DayCareCard2
              bgImage={card2__3}
              title={"Safe and inspiring spaces"}
              text={
                "Our classrooms and outdoor spaces are designed with young children in mind, offering safe, vibrant, and resource-rich environments for exploration and learning. From our indoor discovery zones to our outdoor adventure playgrounds, every space is an opportunity for learning and fun."
              }
            />

            <DayCareCard2
              bgImage={card2__4}
              title={"Daily adventures"}
              text={
                "Every day in our kindergarten is an adventure, beginning with circle time for social interaction, followed by engaging, age-appropriate activities. From sensory play for our youngest learners to literacy and numeracy projects for our pre-schoolers, we ensure a varied and enriching experience. Outdoor play and exploration are also integral, promoting physical health and a connection with nature."
              }
            />
          </div>

          <DayCareCard3 
            bgImage={card3__1}
            icon1={card3__1__icon1}
            icon2={card3__1__icon2}
            icon3={card3__1__icon3}
            title={"Enroll in our Kindergarten"}
            text={"Start your child's educational journey with KosovaKids' Kindergarten, where every day is a step towards growth, discovery, and joy. Contact us to learn more about our programs and how we can support your child's early learning adventure."}
          />

          <DayCareCard1
            bgImage={card1__2}
            text1={"Daycare "}
            text2={"Ages 6-12 years"}
            text3={
              "KosovaKids' Daycare, a place where school-age children aged 6 to 12 find a supportive and engaging environment for after-school care and learning. Our program combines academic support, creative arts, physical activity, and social development to offer a balanced and enriching experience beyond the school day."
            }
          />

          <div className="DayCare__2">
            <DayCareCard2
              bgImage={card2__5}
              title={"Daily schedule"}
              text={
                "Our daily schedule is designed to balance structured learning with free time, allowing children to unwind, complete homework, engage in physical activities, and explore creative interests. With a variety of scheduled activities and free-choice periods, children can explore their interests in a structured yet flexible environment."
              }
            />

            <DayCareCard2
              bgImage={card2__6}
              title={"Fostering individual growth"}
              text={
                "Understanding that each child has unique interests and learning needs, our daycare program offers personalized learning plans. These plans include academic tutoring, hobby classes, and social skills development, ensuring a comprehensive approach to each child's growth."
              }
            />

            <DayCareCard2
              bgImage={card2__7}
              title={"Our top priority"}
              text={
                "The safety and well-being of every child are paramount. Our facilities are secure and monitored, while our staff are experienced and trained in child care and safety protocols. Nutritious snacks and meals are provided to keep children energized and healthy."
              }
            />

            <DayCareCard2
              bgImage={card2__8}
              title={"Enrichment programs"}
              text={
                "Our enrichment programs offer children the chance to explore new interests and develop new skills. From STEM workshops to art classes and sports teams, these programs are designed to enrich the daycare experience, offering something for every child."
              }
            />
          </div>

          <DayCareCard3 
            bgImage={card3__2}
            icon1={card3__2__icon1}
            icon2={card3__2__icon2}
            icon3={card3__2__icon3}
            title={"Enroll in our Daycare"}
            text={"KosovaKids' Daycare is more than just a place to stay after school; it's a community where children grow, learn, and thrive. Contact us to discover more about our daycare services and how we can meet your family's needs."}
          />
        </div>




      </div>
      <Footer />
    </div>
  );
};

export default DayCare;
