import social1 from '../../../assets/images/Navbar-images/facebook.svg';
import social2 from '../../../assets/images/Navbar-images/instagram.svg';
import social3 from '../../../assets/images/Navbar-images/snapchat.svg';
import social4 from '../../../assets/images/Navbar-images/twitter.svg';
import social5 from '../../../assets/images/Navbar-images/linkedin.svg';

export const socialsNavbar = [
    {
        to : "https://www.facebook.com/kosovakidscom",
        social : social1
    },
    {
        to : "https://www.instagram.com/kosovakids/",
        social : social2
    },
    {
        to: "",
        social : social3
    },
    {
        to: "",
        social : social4
    },
    {
        to: "",
        social : social5
    }
]