import React from 'react'
import './CenterCard.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl"

const CenterCard = (props) => {

  return (
    <Link className='centerCard'
        to={
            { 
            pathname: `/ourCenters/${props.id}`, 
            state: { featuredData: props } 
            }
        }
    >
        <div className='kinderGarten'>
            <p>
            <FormattedMessage id="cck"  />
                </p>
        </div>
        <div className='rateChild'>
            <div className='starIcon'></div>
            <p className='rateNumber'>5.0</p>
        </div>
        <div className='centerCardUp'>
            <div className='centerCardUpImg' style={{ content: `url(${props.upperImage})` }}></div>
        </div>
        <div className='centerCardDown'>
            <div className='centerCardDown-up'>
               <div className='centerCardDown-upfirst'>
                <p className='kosovakidsK'>
                <FormattedMessage id="cck2"  />
                    </p>
                <p><span className='dollar'>$</span>{props.paid}</p>
               </div>
               <div className='centerCardDown-upfirst-text'>
                    <p>{props.ccardText}</p>
               </div>
            </div>

            <div className='centerCardDown-second'>
                <div className='centerCardDown-second-content'>
                    <div className='policyIcon' style={{ content: `url(${props.policy})` }} ></div>
                    <div className='centerCardDown-second-content-textual'>
                        <p className='contextCard'>
                        <FormattedMessage id="cck3"  />
                            </p>
                        <p className='ccardAge'>{props.ageGroup}</p>
                    </div>
                </div>
                <div className='centerCardDown-second-content'>
                    <div className='locationIcon' style={{ content: `url(${props.locationIcon})` }}></div>
                    <div className='centerCardDown-second-content-textual'>
                        <p className='contextCard'>
                        <FormattedMessage id="cck4"  />
                            </p>
                        <p className='ccardAge'>{props.location}</p>
                    </div>
                </div>
            </div>

            <div className='centerCardDown-buttons'>
                <div className='leftButtonCardDown button'>
                    <Link>
                    <FormattedMessage id="cck5"  />
                    </Link>
                </div>
                <div className='emptyButton'>
                    <Link>
                    <FormattedMessage id="cck6"  />
                    </Link>
                </div>
                
            </div>

        </div>
    </Link>
  )
}

export default CenterCard
