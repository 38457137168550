import React, { useEffect, useState } from 'react'
import './WhatWeOffer.scss';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel'
import WwoCard from './WwoCard';
import { FormattedMessage } from "react-intl"

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 4 }
  ]
  

const WhatWeOffer = () => {
    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo(0);
      }
    };
    const onPrevStart = (currentItem, nextItem) => {
      if (currentItem.index === nextItem.index) {
        carouselRef.current.goTo("single-slide".length);
      }
    };

    const [data,setData] = useState([])

    const fetchData = async () => {
      try {
        const storedLocale = localStorage.getItem('locale');
        const supportedLocales = ['en-GB', 'sq-AL'];
        const defaultLocale = 'en-GB';
    
        const locale = supportedLocales.includes(storedLocale) ? storedLocale : defaultLocale;
        const api = `/API/WhatWeOffer-${locale}.json`;
    
        console.log('Selected Locale:', locale);
        console.log('API Endpoint:', api);
    
        const response = await fetch(api);
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    useEffect(() => {
      fetchData();
    }, [localStorage.getItem('language')]);


  return (
    <div className='whatWeOffer'>
      <div className='wwoUp'>
        <p>
        <FormattedMessage id="wwoH"  /> <span className='offerRed'> <FormattedMessage id="wwoH2" /> </span> </p>
        <div className='wwButton button'>
            <Link><FormattedMessage id="centersLink"  /></Link>
        </div>
      </div>
      <div className='wwdown'>
            <Carousel
               itemPadding={[10, 15]}
               className="centerCarousel"
               breakPoints={breakPoints}
               ref={carouselRef}
               onPrevStart={onPrevStart}
               onNextStart={onNextStart}
               disableArrowsOnEnd={false}
            >
                {
                    data.map((c) => {
                        return(
                            <WwoCard
                            key={c.id}
                            cicon={c.cicon}
                            title={c.title}
                            des={c.des}
                        />
                        )
                    })
                }

            </Carousel>
      </div>
    </div>
  )
}


export default WhatWeOffer
