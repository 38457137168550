import person1 from "../../../assets/images/OurTeam-images/person1.webp";
import person2 from "../../../assets/images/OurTeam-images/person2.webp";
import person3 from "../../../assets/images/OurTeam-images/person3.webp";
import person4 from "../../../assets/images/OurTeam-images/person4.webp";
import person5 from "../../../assets/images/OurTeam-images/person5.webp";
import person6 from "../../../assets/images/OurTeam-images/person6.webp";

import social1 from "../../../assets/images/OurTeam-images/facebook.svg";
import social2 from "../../../assets/images/OurTeam-images/instagram.svg";
import social3 from "../../../assets/images/OurTeam-images/snapchat.svg";
import social4 from "../../../assets/images/OurTeam-images/twitter.svg";
import social5 from "../../../assets/images/OurTeam-images/linkedin.svg";
import { FormattedMessage } from "react-intl"

export const ourTeamTeacher = [
    {
        circle: "#9066F1",
        person: person1,
        role : <FormattedMessage id="ot3"/>,
        name: "Albana Loxha",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FEC801",
        person: person2,
        role : <FormattedMessage id="ot3"/>,
        name: "Anila Zymeri",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FE570B",
        person: person3,
        role : <FormattedMessage id="ot3"/>,
        name: "Era Gashi",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#9066F1",
        person: person4,
        role : <FormattedMessage id="ot3"/>,
        name: "Art Salihu",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FEC801",
        person: person5,
        role : <FormattedMessage id="ot3"/>,
        name: "Endrit Beka",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FE570B",
        person: person6,
        role : <FormattedMessage id="ot3"/>,
        name: "Endi Elezi",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    }
]