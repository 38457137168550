import React from 'react'
import './WeekEndStay.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import { Link } from 'react-router-dom'

const WeekEndStay = () => {
  return (
    <div className='WeekEndStay'>
        <Navbar />
            <div className="WeekEndStay__inside">
                <div className="WeekEndStay__1">
                    <div className="WeekEndStay__1__filter"></div>
                    <h1 className="ourServiceTitleBg">
                    Weekend Stay
                    </h1>

                    <p className="ourServiceText">
                    Welcome to Weekend Stay Program
                    </p>
                </div>

                <div className="WeekEndStay__2">
                    <div className="left">
                        <h1 className='ourServiceTitle'>
                        What is Weekend Stay?
                        </h1>

                        <p className="ourServiceText">
                        At KosovaKids', we understand that families sometimes need support beyond the traditional school week. Whether you're traveling for work, attending to personal commitments, or simply need a weekend to recharge, our Weekend Stay program offers a safe, engaging, and nurturing environment for your child. Designed for children of all ages, this service ensures that your child's weekend is filled with fun, learning, and adventure.
                        </p>
                    </div>

                    <div className="right">
                        <div className="rightInside rightInside1">
                            <div className="rightInside1__circles"></div>
                            <div className="rightInside1__circle"></div>
                            <div className="rightInside1__star"></div>
                        </div>
                    </div>
                </div>

                <div className="WeekEndStay__3">
                    <div className="left">
                        <div className="rightInside leftInside3">
                            <div className="leftInside3__wave"></div>
                            <div className="leftInside3__magnet"></div>
                            <div className="leftInside3__Image"></div>
                        </div>
                    </div>

                    <div className="right">
                        <h1 className='ourServiceTitle'>
                        A home away from home
                        </h1>

                        <p className="ourServiceText">
                        We strive to make our Weekend Stay program a home away from home, where children feel comfortable, secure, and happy. Our dedicated staff creates a warm and welcoming atmosphere, ensuring a seamless transition from home to our care. We focus on maintaining routine while offering plenty of opportunities for play, relaxation, and learning.
                        </p>
                    </div>
                </div>


                <div className="WeekEndStay__4">
                    <div className="left">
                        <h1 className='ourServiceTitle'>
                        Weekend adventures
                        </h1>

                        <p className="ourServiceText">
                        Our weekends are thoughtfully planned to include a balance of educational activities, outdoor play, creative arts, and free time. From nature hikes and science experiments to cooking classes and storytelling sessions, we ensure that every child finds something they love. Our flexible schedule allows children to choose activities based on their interests, promoting independence and personal growth.
                        </p>
                    </div>

                    <div className="right">
                        <div className="rightInside rightInside3">
                            <div className="rightInside3__circle"></div>
                            <div className="rightInside3__star"></div>
                        </div>
                    </div>
                </div>


                <div className="WeekEndStay__5">
                    <div className="left">
                        <div className="rightInside leftInside4">
                            <div className="leftInside4__circle"></div>
                            <div className="leftInside4__bigCircle"></div>
                        </div>
                    </div>

                    <div className="right">
                        <h1 className='ourServiceTitle'>
                        Connecting with families
                        </h1>

                        <p className="ourServiceText">
                        We believe in keeping the lines of communication open with families. Our staff provides regular updates throughout the weekend, including photos and summaries of your child's activities and experiences. This ensures that you can rest easy knowing your child is having a great time and being well cared for.
                        </p>
                    </div>
                </div>


                <div className="WeekEndStay__6">
                    <h1 className="ourServiceTitle">
                    Peace of mind for parents, fun for kids
                    </h1>

                    <p className="ourServiceText">
                    Our Weekend Stay program is more than just childcare—it's an opportunity for your child to learn, play, and explore in a supportive and enriching environment. Contact us to learn more about how we can support your family's needs, providing peace of mind for parents and a weekend of fun for your child.
                    </p>

                    <Link >
                    Contact us
                    </Link>
                </div>





            </div>

        <Footer />
    </div>
  )
}

export default WeekEndStay