import aicon1 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon1.svg'
import aicon2 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon2.svg'
import aicon3 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon3.svg'
import aicon4 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon4.svg'
import aicon5 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon5.svg'
import aicon6 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon6.svg'
import aicon7 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon7.svg'
import aicon8 from '../../../../../assets/images/Navbar-images/aboutMegamenu-images/icon8.svg'

export const aboutData = [
    {
        class: "cardA1",
        bgColor: "#FFEAEA",
        bgHoverColor: "#FE570B",
        aicon : aicon1,
        title: "About us",
        towher: "aboutus",
        des : "Learn more about us"
    },
    {
        class: "cardA2",
        bgColor: "#FBE9FC",
        bgHoverColor: "#D22BDE",
        aicon : aicon2,
        title: "Courses",
        towher: "ourCourses",
        des : "Start learning new things"
    },
    {
        class: "cardA3",
        bgColor: "#FFFAE5",
        bgHoverColor: "#FEC801",
        aicon : aicon3,
        title: "Our Team",
        towher: "ourTeam",
        des : "Meet our team"
    },
    {
        class: "cardA4",
        bgColor: "#E9FCF8",
        bgHoverColor: "#2BDEBA",
        aicon : aicon4,
        title: "Our History",
        towher: "ourhistory",
        des : "How we started?"
    },
    {
        class: "cardA5",
        bgColor: "#F4EFFE",
        bgHoverColor: "#9066F1",
        aicon : aicon5,
        title: "Our Centers",
        towher: "ourCenters",
        des : "Find our centers"
    },
    {
        class: "cardA6",
        bgColor: "#FFEAEA",
        bgHoverColor: "#FF3636",
        aicon : aicon6,
        title: "Pricing",
        towher: "pricing",
        des : "Check our pricing"
    },
    {
        class: "cardA7",
        bgColor: "#F8E6FF",
        bgHoverColor: "#B60BFE",
        aicon : aicon7,
        title: "Our Shop",
        des : "Donate while shopping"
    },
    {
        class: "cardA8",
        bgColor: "#EAF1FF",
        bgHoverColor: "#3679FF",
        aicon : aicon8,
        title: "Gallery",
        towher: "gallery",
        des : "Check our environments"
    }
]