import React, { useEffect, useState } from 'react'
import './PricingOffers.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl"

const PricingOffers = () => {

    const [data,setData] = useState([])

    const fetchData = async () => {
        try {
          const storedLocale = localStorage.getItem('locale');
          const supportedLocales = ['en-GB', 'sq-AL'];
          const defaultLocale = 'en-GB';
      
          const locale = supportedLocales.includes(storedLocale) ? storedLocale : defaultLocale;
          const api = `/API/PricingOffers-${locale}.json`;
      
          console.log('Selected Locale:', locale);
          console.log('API Endpoint:', api);
      
          const response = await fetch(api);
          const data = await response.json();
          setData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      useEffect(() => {
        fetchData();
      }, [localStorage.getItem('language')]);
  return (
    <div className='pricingOffers'>

       <div className='eventsActivities-heading'>
        <p><FormattedMessage id="prc1"  /> & <span className='yellowA'><FormattedMessage id="prc2"  /> </span></p>
      </div>

      <div className='pricingOffersCards'>
        {
            data.map((i,index) => {
                return(
                    <div className={`pocard ${i.class}`}>
                        <div className='pocardUpper'>
                            <p className='pcstat'>{i.careStatus}</p>
                            <div className='pocardMiddleUp'>
                                <p className='buyingpc'>{i.buying}</p>
                                <p className='methodpc'>{i.method}</p>
                            </div>
                            <div className='pocardUpDown'>
                                <div className='pocardDownUpLeft'>
                                    <p className='pctime'>{i.time}</p>
                                    <p className='pcdays'>{i.days}</p>
                                </div>
                                <div className='pocardDownUpRight button'>
                                        <Link className='applyLink'>{i.apply}</Link>
                                </div>
                            </div>
                        </div>
                        <div className='pocardDown'>
                            {
                                i.icons.map((ic,index2) => {
                                    return(
                                        <div className='pocardDownRow'>
                                            <div className='pocardDownRowIcon'style={{ content: `url(${ic.cardIc})` }} ></div>
                                            <p>{ic.cardText}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        }
        <div className='rightCards'>

            <div className='leftRightCards'>
                <div className='pcEcard card7'>
                <div className='pocardUpper'>
                            <p className='pcstat'><FormattedMessage id="prcc1"/> </p>
                            <div className='pocardMiddleUp'>
                                <p className='buyingpc'>€40</p>
                                <p className='methodpc'><FormattedMessage id="prcc1-1"/></p>
                            </div>
                            <div className='pocardUpDown'>
                                <div className='pocardDownUpLeft'>
                                    <p className='pctime'>24h</p>
                                    <p className='pcdays'><FormattedMessage id="prcc1-2"/></p>
                                </div>
                                <div className='pocardDownUpRight button'>
                                        <Link className='applyLink'><FormattedMessage id="applyPrc"/></Link>
                                </div>
                            </div>
                        </div>
                        <div className='pocardDown'>
                                        <div className='pocardDownRow'>
                                            <div className='pocardDownRowIcon extracardicon1' ></div>
                                            <p>
                                            <FormattedMessage id="prcc1-3"/>
                                                </p>
                                        </div>
                                        <div className='pocardDownRow'>
                                            <div className='pocardDownRowIcon extracardicon2' ></div>
                                            <p>
                                            <FormattedMessage id="prcc1-4"/>
                                                </p>
                                        </div>
                                        <div className='pocardDownRow'>
                                            <div className='pocardDownRowIcon extracardicon3' ></div>
                                            <p>
                                            <FormattedMessage id="prcc1-5"/>
                                                </p>
                                        </div>
                                     </div>
                </div>
                <div className='pcEcard card8'>
                <div className='pocardUpper'>
                            <p className='pcstat'><FormattedMessage id="prcc2-1"/></p>
                            <div className='pocardMiddleUp'>
                                <p className='buyingpc'>€20</p>
                                <p className='methodpc'><FormattedMessage id="prcc2-2"/></p>
                            </div>
                            <div className='pocardUpDown'>
                                <div className='pocardDownUpLeft'>
                                    <p className='pctime'>24h</p>
                                    <p className='pcdays'><FormattedMessage id="prcc2-3"/></p>
                                </div>
                                <div className='pocardDownUpRight button'>
                                        <Link className='applyLink'><FormattedMessage id="applyPrc"/></Link>
                                </div>
                            </div>
                        </div>
                        <div className='pocardDown'>
                        <div className='pocardDownRow'>
                                            <div className='pocardDownRowIcon extracardicon1' ></div>
                                            <p>
                                            <FormattedMessage id="prcc1-3"/>
                                                </p>
                                        </div>
                                        <div className='pocardDownRow'>
                                            <div className='pocardDownRowIcon extracardicon2' ></div>
                                            <p>
                                            <FormattedMessage id="prcc1-4"/>
                                                </p>
                                        </div>
                                        <div className='pocardDownRow'>
                                            <div className='pocardDownRowIcon extracardicon3' ></div>
                                            <p>
                                            <FormattedMessage id="prcc1-5"/>
                                                </p>
                                        </div>
                                     </div>
                </div>
            </div>
            <div className='rightRightcards'>
            <div className='pcEcard card9'>
                <div className='pocardUpper'>
                            <p className='pcstat'><FormattedMessage id="prcc3-1"/></p>
                            <div className='pocardMiddleUp'>
                                <p className='buyingpc'>€4</p>
                                <p className='methodpc'><FormattedMessage id="prcc3-2"/></p>
                            </div>
                            <div className='pocardUpDown'>
                                <div className='pocardDownUpLeft'>
                                    <p className='pcdays'><FormattedMessage id="prcc3-3"/></p>
                                </div>
                                <div className='pocardDownUpRight button'>
                                        <Link className='applyLink'><FormattedMessage id="applyPrc"/></Link>
                                </div>
                            </div>
                        </div>
                        <div className='pocardDown'>
                                        <div className='pocardDownRow'>
                                            <div className='pocardDownRowIcon extracardicon1' ></div>
                                            <p> <FormattedMessage id="prcc1-3"/></p>
                                        </div>
                                     </div>
                </div>
                <div className='pcEcard card10 extracard'>
                <div className='pocardUpper'>
                           <div className='pcardLogoHolder'>
                            <div className='logoPecCard'></div>
                            <p>KosovaKids</p>
                           </div>
                        </div>
                        <div className='pocardDown'>
                            <p><FormattedMessage id="prcc4-1"/></p>
                            <div className='extracardButton button'>
                                <Link><FormattedMessage id="prcc4-2"/></Link>    
                            </div>         
                        </div>
                </div>
            </div>
           
        </div>
      </div>

      <div className='viewAllPricesButtonHolder'>
        <div className='viewAllButton button'>
             <Link to="/pricing"><FormattedMessage id="prcc4-3"/></Link> 
        </div>
      </div>
    </div>
  )
}

export default PricingOffers
