import React from 'react'
import './Shop.scss';
import { Link } from 'react-router-dom';
import { carouselData, coursesData, shopData } from './ShopData';
import Carousel from 'react-elastic-carousel'

const Shop = () => {

    const breakPoints = [
        { width: 100, itemsToShow: 2 },
        { width: 1750, itemsToShow: 2 },
    ];

    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo("single-slide".length);
        }
    };

  return (
    <div className='aboutMegamenu' >
        <div className='aboutLeft'>
            <div className='aboutLeftText'>
                <p className='extraShop'>SHOP</p>
            </div>
            <div className='aboutLeftCards'>
                {
                    shopData.map((ab,index) =>{
                        return(
                            <Link className={`aboutMegamenuCard ${ab.class}`}>
                                <div className='acardHolderImg'>
                                        <div className='acMIco' style={{ content: `url(${ab.aicon})`}}></div>
                                </div>
                                <div className='acardRightContent'>
                                    <p className='acardTitle'>{ab.title}</p>
                                    <p className='acardText'>{ab.des}</p>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
        <div className='coursesRight'>
                <div className='aboutRightText'>
                    <p>FROM OUR SHOP</p>
                </div>
                <Carousel
                itemPadding={[10, 10]}
                className="expCarousel"
                breakPoints={breakPoints}
                ref={carouselRef}
                onPrevStart={onPrevStart}
                onNextStart={onNextStart}
                disableArrowsOnEnd={false}
                >
                        {
                            carouselData.map((cd,index) => {
                                return(
                                    <Link className='coursesCard' key={index}>
                                        <div className='shopLogo'></div>
                                       <div className='imageHolder'>
                                            <div className='courseCardImg' style={{ content: `url(${cd.carouselImg})` }}></div>
                                       </div>
                                        <div className='carouselDown'>
                                            <p className='pName'>{cd.itemName}</p>
                                            <p className='pPrice'>{cd.itemPrice}</p>
                                        </div>
                                    </Link>
                                )
                            })
                        }

                </Carousel>

                <div className='shopButton button'>
                    <Link>View our shop</Link>
                </div>
        </div>
    </div>
  )
}

export default Shop
