import React, { useEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel'
import { Link } from 'react-router-dom';
import ShopToDonateCard from './ShopToDonateCard';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 }
]

const ShopToDonateTabPanel = () => {
    const [data,setData] = useState([])
    const fetchData = async () => {
      try {
        const storedLocale = localStorage.getItem('locale');
        const supportedLocales = ['en-GB', 'sq-AL'];
        const defaultLocale = 'en-GB';
    
        const locale = supportedLocales.includes(storedLocale) ? storedLocale : defaultLocale;
        const api = `/API/ShopToDonate-${locale}.json`;
    
        console.log('Selected Locale:', locale);
        console.log('API Endpoint:', api);
    
        const response = await fetch(api);
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    useEffect(() => {
      fetchData();
    }, [localStorage.getItem('language')]);

  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };
  return (
    <div className='centerTabPanel'>
    <Carousel
     itemPadding={[10, 20]}
     className="centerCarousel"
     breakPoints={breakPoints}
     ref={carouselRef}
     onPrevStart={onPrevStart}
     onNextStart={onNextStart}
     disableArrowsOnEnd={false}
    >
      
      {
        data.map((s) => {
          return(
            <ShopToDonateCard
              upperImage={`${process.env.REACT_APP_ShopToDonateImages_IMAGE}${s.upperImage}`}
              type={s.type}
              heartI={s.heartI}
              cartI={s.cartI}
              id={s.id}
            />
          )
        })
      }

    </Carousel>
      
  </div>
  )
}

export default ShopToDonateTabPanel
