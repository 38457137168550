import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from "react-intl"

const WwoCard = (props) => {
  return (
    <Link className='wwocard'>
        <div className='wwocardUp'>
        <div className='wwoIcon' style={{ content: `url(${props.cicon})` }} ></div>
        </div>
        <div className='wwocardTitle'>
            <p>{props.title}</p>
        </div>
        <div className='wwoCardText'>
            <p>{props.des}</p>
        </div>
        <div className='wwocardButton button'>
            <Link>
            <FormattedMessage id="wwoLink"  /> 
            </Link>
        </div>
    </Link>
  )
}

export default WwoCard
