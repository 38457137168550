import React from 'react'
import './OurTeamPage.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import OurGallery from '../../components/pages/OurGallery/OurGallery'
import WhatPeopleSay from '../../components/pages/WhatPeopleSay/WhatPeopleSay'
import GetOurApps from '../../components/pages/GetOurApps/GetOurApps'
import YouCanLearn from '../../components/pages/YouCanLearn/YouCanLearn'
import Footer from '../../components/shared/Footer/Footer'
import OurPage from '../../components/pages/OurTeamPage/OurPage'

const OurTeamPage = () => {
  return (
    <div className='OurTeamPage'>
        <Navbar />
        <OurPage />
        <OurGallery/>
        <WhatPeopleSay/>
        <GetOurApps/>
        <YouCanLearn />
        <Footer/> 
    </div>
  )
}

export default OurTeamPage