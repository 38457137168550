import React from "react";
import "./FoodAndCare.scss";

const FoodAndCare = (props) => {
  return (
    <div className="FoodAndCare">
      <div className="wwoUp">
        <p>
          Food and
          <span className="offerRed">&nbsp;care</span>
        </p>
      </div>

      <div className="FoodAndCare__inside">
        <div className="food__card">
          <div className="cardTop cardtop1"></div>
          <h2>Healthy meals</h2>

          <p>
            {props.healthtMeal1}
          </p>

          <p>
            {props.healthtMeal2}
          </p>
        </div>

        <div className="food__card">
          <div className="cardTop cardtop2"></div>
          <h2>Kids wellbeing</h2>

          <p>
            {props.healthtMeal3}
          </p>

          <p>
            {props.healthtMeal4}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FoodAndCare;
