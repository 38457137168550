import React from 'react'
import './ShopToDonate.scss';
import { Link } from 'react-router-dom';
import { Tab, TabList, Tabs ,TabPanel} from 'react-tabs';
import { FormattedMessage } from "react-intl"
import 'react-tabs/style/react-tabs.css';
import { shopTabs } from './shopToDonateData';
import ShopToDonateTabPanel from './ShopToDonateTabPanel';

const ShopToDonate = () => {
  return (
    <div className='shopToDonate'>
    <div className='centersUp'>
        <p className='ourCoursesP'><span className='lightDark'><FormattedMessage id="shtD1"/></span> <span className='offerRed'><FormattedMessage id="shtD2"/></span></p>
        <div className='viewAllCentres button'>
            <Link><FormattedMessage id="centersLink"  /></Link>
        </div>
    </div>
    <Tabs className="centersTabs">
        <TabList className="centerTablists">
                {
                    shopTabs.map((t,index) => {
                        return(
                            <Tab className='tabListCenter button' key={index}>
                                <p>{t.city}</p>
                            </Tab>
                        )
                    })
                }
        </TabList>

        <TabPanel>
            <ShopToDonateTabPanel/>
        </TabPanel>

        <TabPanel>
            <ShopToDonateTabPanel/>
        </TabPanel>

        <TabPanel>
            <ShopToDonateTabPanel/>
        </TabPanel>

        <TabPanel>
            <ShopToDonateTabPanel/>
        </TabPanel>

        <TabPanel>
            <ShopToDonateTabPanel/>
        </TabPanel>

        <TabPanel>
            <ShopToDonateTabPanel/>
        </TabPanel>
        </Tabs>
    </div>
  )
}

export default ShopToDonate
