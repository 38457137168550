import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from "react-intl"

const EaCard = (props) => {
  return (
    <Link className='eaCard'>
        <div className='leftEaCard'>
            <div className='leftEaCardImgHolder' >
                <div className='leftEaCardImg' style={{ content: `url(${props.leftImg})` }}></div>
            </div>
            <div className='eacardIcon' style={{ content: `url(${props.logo})` }} ></div>
        </div>
        <div className='rightEaCard'>
            <div className='eacardButtonBig'>
                <p>{props.title}</p>
            </div>
            <div className='eacardMiddle'>
                <p>{props.des}</p>
            </div>
            <div className='eacardInfos flex2'>
                <div className='eacardPolicy flex1'>
                    <div className='eacardIconpolicy '></div>
                    <div className='eacardAgeGroup'>
                        <p className='contextCard'><FormattedMessage id="evec1"/></p>
                        <p className='ccardAge'>5-7 years</p>
                    </div>
                </div>
                <div className='eacardInfosMiddle flex1'>
                    <div className='eacardLocationPolicy'></div>
                    <div className='eacardAgeGroup'>
                        <p className='contextCard'><FormattedMessage id="evec2"/></p>
                        <p className='ccardAge'>Prishtina, Kosovo</p>
                    </div>
                </div>
                <div className='eacardPrice'>
                    <p><span className='dollar'>$</span>90/mo</p>
                </div>
            </div>
            <div className='eacardDown'>
                <div className='eacardDownButton button'>
                    <Link>
                    <FormattedMessage id="evec3"/>
                    </Link>
                </div>
                <div className='eacardDownBtn emptybutton'>
                    <Link>
                    <FormattedMessage id="evec4"/>
                    </Link>
                </div>
            </div>
        </div>
    </Link>
  )
}

export default EaCard
