import React from 'react'
import Navbar from '../../components/shared/Navbar/Navbar'
import Banner from '../../components/pages/Banner/Banner'
import AboutUs from '../../components/pages/AboutUs/AboutUs'
import Centers from '../../components/pages/Centers/Centers'
import FlexibileSol from '../../components/pages/FlexibileSol/FlexibileSol'
import WhatWeOffer from '../../components/pages/WhatWeOffer/WhatWeOffer'
import NumbersRising from '../../components/pages/NumbersRising/NumbersRising'
import OurCourses from '../../components/pages/OurCourses/OurCourses'
import KosovaKidsCenter from '../../components/pages/KosovaKidsCenter/KosovaKidsCenter'
import Footer from '../../components/shared/Footer/Footer'
import ShopToDonate from '../../components/pages/ShopToDonate/ShopToDonate'
import Curriculum from '../../components/pages/Curriculum/Curriculum'
import EventsActivities from '../../components/pages/EventsActivities/EventsActivities'
import PricingOffers from '../../components/pages/PricingOffers/PricingOffers'
import OurGallery from '../../components/pages/OurGallery/OurGallery'
import OurTeam from '../../components/pages/OurTeam/OurTeam'
import WhatPeopleSay from '../../components/pages/WhatPeopleSay/WhatPeopleSay'
import GetOurApps from '../../components/pages/GetOurApps/GetOurApps'
import YouCanLearn from '../../components/pages/YouCanLearn/YouCanLearn'

const Home = () => {
  return (
    <div className='home-page'>
      <Navbar/>
      <Banner/>
      <AboutUs/>
      <Centers/>
      <FlexibileSol/>
      <WhatWeOffer/>
      <NumbersRising/>
      <OurCourses/>
      <KosovaKidsCenter/>
      <ShopToDonate/>
      <Curriculum/>
      <EventsActivities/>
      <PricingOffers/>
      <OurGallery/>
      <OurTeam/>
      <WhatPeopleSay/>
      <GetOurApps/>
      <YouCanLearn/>
      <Footer/>
    </div>
  )
}

export default Home
