import React from 'react'

const WhatPeopleSayCard = (props) => {
  return (
    <div className='whatPeopleSayCard'>
        <div className='whatCardUp'>
          {/* <div className='bigCircleBgWhat' style={{ content: `url(${props.circle})` }}></div> */}
          <div className='bigCircleBgWhat'></div>
          <div className='whatcardImgHolder'>
            <div className='whatCardImg'  style={{ content: `url(${props.anotherPerson})` }}></div>
          </div>
        </div>
        <div className='bracesIcon'  style={{ content: `url(${props.braces})` }}></div>
        <div className='whatCardText'>
          <p>{props.cardDes}</p>
        </div>
        <div className='whatcardDown'>
          <div className='whatLeft'>
            <p className='whatRole'>{props.role}</p>
            <p className='whatName'>{props.name}</p>
          </div>
          <div className='ratingStars'>
            <div className='starRate' style={{ content: `url(${props.star})` }}></div>
            <div className='starRate' style={{ content: `url(${props.star})` }}></div>
            <div className='starRate' style={{ content: `url(${props.star})` }}></div>
            <div className='starRate' style={{ content: `url(${props.star})` }}></div>
            <div className='starRate' style={{ content: `url(${props.star})` }}></div>
            <p>5.0</p>
          </div>
        </div>
    </div>
  )
}

export default WhatPeopleSayCard
