import React from 'react'
import './NightStay.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../../components/shared/Footer/Footer'

const NightStay = () => {
  return (
    <div className='NightStay'>
        <Navbar />
            <div className="WeekEndStay__inside NightStay__inside">
                <div className="WeekEndStay__1 NightStay__1">
                    <div className="WeekEndStay__1__filter "></div>
                    <h1 className="ourServiceTitleBg">
                    Night Stay
                    </h1>

                    <p className="ourServiceText">
                    Something came up? No worries we take care of your kids
                    </p>
                </div>

                <div className="WeekEndStay__2 NightStay__2">
                    <div className="left">
                        <h1 className='ourServiceTitle'>
                        What is Night Stay?
                        </h1>

                        <p className="ourServiceText">
                        At KosovaKids, we recognize that life can be unpredictable. Whether it's a sudden work obligation, an unexpected event, or any situation that requires you to be away overnight, our Night Stay program is here to support your family. Designed to accommodate children of all ages in a safe, comforting environment, we ensure that your child is cared for, comfortable, and content until you can be reunited.
                        </p>
                    </div>

                    <div className="right">
                        <div className="rightInside rightInside1 nightRight1">
                            <div className="nightInside1__moon"></div>
                            <div className="nightInside1__circle"></div>
                        </div>
                    </div>
                </div>

                <div className="WeekEndStay__3 NightStay__3">
                    <div className="left">
                        <div className="rightInside leftInside3 nightRight2">
                            <div className="nightInside2__star"></div>
                            <div className="nightInside2__cloud"></div>
                        </div>
                    </div>

                    <div className="right">
                        <h1 className='ourServiceTitle'>
                        Our commitment
                        </h1>

                        <p className="ourServiceText">
                        Our Night Stay program is built around the principles of safety, comfort, and the overall well-being of your child. Our facilities are secure, with round-the-clock supervision by our highly trained and compassionate staff. We provide cozy sleeping arrangements, ensuring that each child feels safe and at home. Our approach is to maintain as much of your child's normal routine as possible, providing a sense of stability and normalcy.
                        </p>
                    </div>
                </div>


                <div className="WeekEndStay__4 NightStay__4">
                    <div className="left">
                        <h1 className='ourServiceTitle'>
                        Communication with parents
                        </h1>

                        <p className="ourServiceText">
                        We understand how important it is for parents to stay informed and connected. Our Night Stay program includes regular updates for parents, providing reassurance and peace of mind. Whether through text messages, emails, or phone calls, we keep you updated on your child's well-being, activities, and sleep status.
                        </p>
                    </div>

                    <div className="right ">
                        <div className="rightInside rightInside3 nightRight3">
                            <div className="nightInside3__magnet"></div>
                            <div className="nightInside3__circle"></div>
                        </div>
                    </div>
                </div>



                <div className="WeekEndStay__6 NightStay__6">
                    <h1 className="ourServiceTitle">
                    Your trusted partner for your kids
                    </h1>

                    <p className="ourServiceText">
                    Let KosovaKids be your trusted partner, providing care and comfort for your child during unexpected situations. Our Night Stay program is here to ensure that your child is in safe hands, allowing you to focus on your commitments with peace of mind. Contact us to learn more about this valuable service and how we can assist your family during those unexpected times.
                    </p>

                    <Link >
                    Contact us
                    </Link>
                </div>





            </div>

        <Footer />
    </div>
  )
}

export default NightStay