import cicon1 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon1.svg"
import cicon2 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon2.svg"
import cicon3 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon3.svg"
import cicon4 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon4.svg"
import cicon5 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon5.svg"
import cicon6 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon6.svg"
import cicon7 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon7.svg"
import cicon8 from "../../../../../assets/images/Navbar-images/coursesMegamenu-images/cicon8.svg"

import carouselImg1 from '../../../../../assets/images/Navbar-images/coursesMegamenu-images/carouselItem.webp';
import profile1 from '../../../../../assets/images/Navbar-images/coursesMegamenu-images/carouselProfile.webp';
import clockIcon from '../../../../../assets/images/Navbar-images/coursesMegamenu-images/clock.svg';

export const coursesData = [
    {
        class: "cardA1",
        bgColor: "#FFEAEA",
        aicon : cicon1,
        title: "Robotics For Kids",
    },
    {
        class: "cardA2",
        bgColor: "#FBE9FC",
        aicon : cicon2,
        title: "Soft Skills",
    },
    {
        class: "cardA3",
        bgColor: "#FFFAE5",
        aicon : cicon3,
        title: "IT For Kids",
    },
    {
        class: "cardA4",
        bgColor: "#E9FCF8",
        aicon : cicon4,
        title: "Foreign Languages",
    },
    {
        class: "cardA5",
        bgColor: "#F4EFFE",
        aicon : cicon5,
        title: "Graphic Design For Kids",
    },
    {
        class: "cardA6",
        bgColor: "#FFEAEA",
        aicon : cicon6,
        title: "Sports",
    },
    {
        class: "cardA7",
        bgColor: "#F8E6FF",
        aicon : cicon7,
        title: "Science For Kids",
    },
    {
        class: "cardA8",
        bgColor: "#EAF1FF",
        aicon : cicon8,
        title: "Music & Arts",
    }
]


export const carouselDataC = [
    {
        carouselImg : carouselImg1,
        programming: "Programming For Kids",
        price : "$ 100/mo",
        profile: profile1,
        instructor : "Instructor",
        name : "Ana Smith",
        clockIcon : clockIcon,
        schedule: "Schedule",
        time: "16:00 - 18:00"
    },
    {
        carouselImg : carouselImg1,
        programming: "Programming For Kids",
        price : "$ 100/mo",
        profile: profile1,
        instructor : "Instructor",
        name : "Ana Smith",
        clockIcon : clockIcon,
        schedule: "Schedule",
        time: "16:00 - 18:00"
    },
    {
        carouselImg : carouselImg1,
        programming: "Programming For Kids",
        price : "$ 100/mo",
        profile: profile1,
        instructor : "Instructor",
        name : "Ana Smith",
        clockIcon : clockIcon,
        schedule: "Schedule",
        time: "16:00 - 18:00"
    },
    {
        carouselImg : carouselImg1,
        programming: "Programming For Kids",
        price : "$ 100/mo",
        profile: profile1,
        instructor : "Instructor",
        name : "Ana Smith",
        clockIcon : clockIcon,
        schedule: "Schedule",
        time: "16:00 - 18:00"
    }
]