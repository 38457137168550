import React, { useEffect, useState } from "react";
import "./AboutUsGallery.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import axios from "axios";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const AboutUsGallery = () => {
  const [pageData, setPageData] = useState([]);
  const [pageCenters, setPageCenters] = useState([]);
  const [pageDaycare, setPageDaycare] = useState([]);
  const [pageCourses, setPageCourses] = useState([]);
  const [perPageNumber, setPerPageNumber] = useState(12);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}AboutUsGallery.json`)
      .then((res) => {
        setPageData(res.data);
        setPageCenters(res.data.filter((item) => item.name === "Centers"));
        setPageDaycare(res.data.filter((item) => item.name === "Daycare"));
        setPageCourses(res.data.filter((item) => item.name === "Courses"));
      })
      .catch((error) => {
        console.error("AboutUsGallery : ", error);
      });
  }, []);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = perPageNumber;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const npage = Math.ceil(pageData.length / recordsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const numbersToShow = 5;
  const totalPages = npage;

  const getPageNumbers = () => {
    const midNumber = Math.floor(numbersToShow / 2);
    let startPage = Math.max(1, currentPage - midNumber);
    let endPage = Math.min(startPage + numbersToShow - 1, totalPages);

    if (endPage - startPage + 1 < numbersToShow) {
      startPage = Math.max(1, endPage - numbersToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const filteredAndPaginatedData = pageData.slice(
    (currentPage - 1) * perPageNumber,
    currentPage * perPageNumber
  );

  return (
    <div className="AboutUsGallery">
      <Navbar />

      <div className="AboutUsGallery__inside">
        <div className="AboutUsGallery__1">
          <div className="AboutUsGallery__magnet"></div>
          <div className="AboutUsGallery__star"></div>
          <div className="AboutUsGallery__circle"></div>
          <div className="AboutUsGallery__wave"></div>

          <h1>Gallery</h1>

          <p>
            Take a look at our facilities and happy moments we share with kids
          </p>
        </div>

        <Tabs className="AboutUsGallery__tabs">
          <TabList className="AboutUsGallery__tablist eactbl">
            <Tab className="AboutUsGallery__tab eact">
              <span className="changeColorAboutUsGallery">Centers</span>
            </Tab>
            <Tab className="AboutUsGallery__tab eact">
              <span className="changeColorAboutUsGallery">Daycare</span>
            </Tab>
            <Tab className="AboutUsGallery__tab eact">
              <span className="changeColorAboutUsGallery">Courses</span>
            </Tab>
          </TabList>

          <TabPanel>
            <div className="tabpanel__aboutusgallery">
              {pageCenters.map((center, index) => (
                <React.Fragment key={index}>
                  {Object.keys(center).map((key) => {
                    if (key.startsWith("image")) {
                      return (
                        <div
                          key={key}
                          className={`aboutusgallery__image ${key}`}
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_AboutUsGallery_IMAGE}${center[key]})`,
                          }}
                        ></div>
                      );
                    }
                    return null;
                  })}
                </React.Fragment>
              ))}
            </div>

            <div className="bottomEducation__right__bottom">
              <ul className="bottom__numbers">
                <p onClick={prePage} className="arrowPagination">
                  <FaArrowLeftLong />
                </p>

                {getPageNumbers().map((n) => (
                  <li
                    key={n}
                    className={`page-item ${
                      currentPage === n ? "activeBottom" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      onClick={() => changeCPage(n)}
                      className="page-link"
                    >
                      {n}
                    </Link>
                  </li>
                ))}

                <p onClick={nextPage} className="arrowPagination">
                  <FaArrowRightLong />
                </p>
              </ul>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="tabpanel__aboutusgallery">
              {pageDaycare.map((daycare, index) => (
                <React.Fragment key={index}>
                  {Object.keys(daycare).map((key) => {
                    if (key.startsWith("image")) {
                      return (
                        <div
                          key={key}
                          className={`aboutusgallery__image ${key}`}
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_AboutUsGallery_IMAGE}${daycare[key]})`,
                          }}
                        ></div>
                      );
                    }
                    return null;
                  })}
                </React.Fragment>
              ))}
            </div>

            <div className="bottomEducation__right__bottom">
              <ul className="bottom__numbers">
                <p onClick={prePage} className="arrowPagination">
                  <FaArrowLeftLong />
                </p>

                {getPageNumbers().map((n) => (
                  <li
                    key={n}
                    className={`page-item ${
                      currentPage === n ? "activeBottom" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      onClick={() => changeCPage(n)}
                      className="page-link"
                    >
                      {n}
                    </Link>
                  </li>
                ))}

                <p onClick={nextPage} className="arrowPagination">
                  <FaArrowRightLong />
                </p>
              </ul>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="tabpanel__aboutusgallery">
              {pageCourses.map((course, index) => (
                <React.Fragment key={index}>
                  {Object.keys(course).map((key) => {
                    if (key.startsWith("image")) {
                      return (
                        <div
                          key={key}
                          className={`aboutusgallery__image ${key}`}
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_AboutUsGallery_IMAGE}${course[key]})`,
                          }}
                        ></div>
                      );
                    }
                    return null;
                  })}
                </React.Fragment>
              ))}
            </div>

            <div className="bottomEducation__right__bottom">
              <ul className="bottom__numbers">
                <p onClick={prePage} className="arrowPagination">
                  <FaArrowLeftLong />
                </p>

                {getPageNumbers().map((n) => (
                  <li
                    key={n}
                    className={`page-item ${
                      currentPage === n ? "activeBottom" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      onClick={() => changeCPage(n)}
                      className="page-link"
                    >
                      {n}
                    </Link>
                  </li>
                ))}

                <p onClick={nextPage} className="arrowPagination">
                  <FaArrowRightLong />
                </p>
              </ul>
            </div>
          </TabPanel>
        </Tabs>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUsGallery;
