import {lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Aos from 'aos';
import "aos/dist/aos.css";
import './App.scss';
import Loading from './components/shared/Loading/Loading';
import './DarkTheme.scss';
import './PreferedTheme.scss';
import {IntlProvider} from 'react-intl';
import { translate } from './translation/translate';
import Home from './pages/Home/Home';
import { useSelector } from 'react-redux';
import Donate from './pages/Donate/Donate';
import AboutUs from './pages/AboutUsPage/AboutUsPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import OurTeamPage from './pages/OurTeamPage/OurTeamPage';
import OurCenterDetail from './pages/OurCenterDetail/OurCenterDetail';
import BlogPage from './pages/BlogPage/BlogPage';
import CheckOurCourses from './pages/CheckOurCourses/CheckOurCourses';
import AboutUsGallery from './pages/AboutUsGallery/AboutUsGallery';
import OurHistory from './pages/OurHistory/OurHistory';
import BlogDetails from './pages/BlogDetails/BlogDetails';
import CoursesDetails from './pages/CoursesDetails/CoursesDetails';
import WeekEndStay from './pages/WeekEndStay/WeekEndStay';
import NightStay from './pages/NightStay/NightStay';
import HalfDayCare from './pages/HalfDayCare/HalfDayCare';
import DayCare from './pages/DayCare/DayCare';

const Favourites = lazy(() => import('./components/shared/Favourites/Favourites'))
const Apply = lazy(() => import('./pages/Apply/Apply'))
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'))
const Pricing = lazy(() => import('./pages/Pricing/Pricing'))
const OurCenters = lazy(() => import('./pages/OurCenters/OurCenters'))

function App() {
  useEffect(() => {
    Aos.init({
      duration:1200,
      once: true
    })
  }, [])

  const language = useSelector((state) => state.language.language );

  return (
    <div className="Home">
       

       <Router>
        <Suspense fallback={<Loading />} >
        <IntlProvider
        locale={language}
        formats={{ number: 'en' }}
        messages={translate[language]}
        >
          <ScrollToTop />
        
          <Routes>

              <Route path="/apply" element={<Apply/>} />
              <Route path="/contactUs" element={<ContactUs/>} />
              <Route path="/favourites" element={<Favourites/>} />
              <Route path="/pricing" element={<Pricing/>} />
              <Route path="/ourCenters" element={<OurCenters/>} />
              <Route exact path="/" element={<Home />} />

              <Route path="/donate" element={<Donate/>} />
              <Route path="/aboutus" element={<AboutUsPage/>} />
              <Route path="/ourTeam" element={<OurTeamPage/>} />
              <Route path="/ourCenters/:id" element={<OurCenterDetail/>} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/ourCourses" element={<CheckOurCourses/>} />

              <Route path="/gallery" element={<AboutUsGallery/>} />
              <Route path="/ourhistory" element={<OurHistory/>} />
              <Route path="/aboutus/ourTeam" element={<OurTeamPage/>} />

              <Route path="/blogDetails/:id" element={<BlogDetails/>} />
              <Route path="/coursesDetails/:id/:childish" element={<CoursesDetails/>} />

              <Route path="/weekEndStay" element={<WeekEndStay/>} />
              <Route path="/nightStay" element={<NightStay/>} />
              <Route path="/halfDayCare" element={<HalfDayCare/>} />
              <Route path="/dayCare" element={<DayCare/>} />


              <Route path="*" element={<Navigate to ="/" />}/>
          </Routes>
          </IntlProvider>
      </Suspense>

        </Router>

    </div>
  );
}

export default App;
