import React, { useState } from 'react'
import "./NumbersRising.scss";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { FormattedMessage } from "react-intl"

const NumbersRising = () => {
    const [counterOn,setCounterOn] = useState(false);
  return (
    <div className='numbersRising'>
        <ScrollTrigger onEnter={() => setCounterOn(true) } onExit={() => setCounterOn(false)} className="numbers">
        <div className='number'>
        <p className='countNum'> 
            {
                counterOn && (
                    <CountUp start={0} end={20} duration={2} delay={0}/>
                )
            }
            +
        </p>
        <p className='numText'>
        <FormattedMessage id="num1"  />
            </p>
      </div>
      <div className='number'>
      <p className='countNum'> 
            {
                counterOn && (
                    <CountUp start={0} end={300} duration={2} delay={0}/>
                )
            }
            +
        </p>
        <p className='numText'>
        <FormattedMessage id="num2"  />
            </p>
      </div>
      <div className='number'>
      <p className='countNum'> 
            {
                counterOn && (
                    <CountUp start={0} end={30} duration={2} delay={0}/>
                )
            }
            +
        </p>
        <p className='numText'>
        <FormattedMessage id="num3"  />
            </p>
      </div>
      <div className='number'>
      <p className='countNum'> 
            {
                counterOn && (
                    <CountUp start={0} end={20} duration={2} delay={0}/>
                )
            }
            +
        </p>
        <p className='numText'>
        <FormattedMessage id="num4"  />
            </p>
      </div>
        </ScrollTrigger>
     
    </div>
  )
}

export default NumbersRising
