import { FormattedMessage } from "react-intl"

export const footerLinks = [

    {
        title: "KosovaKids",
        links : [
            {
                link: <FormattedMessage id="f1"  />,
                whereTo: "/aboutus"
            },
            {
                link: <FormattedMessage id="f2"  />,
                whereTo: "/ourTeam"
            },

            {
              link: "Our centers",
              whereTo: "/ourCenters"
            },

            {
              link: "Pricing",
              whereTo: "/pricing"
            },

            {
              link: "Our history",
              whereTo: "/ourhistory"
            },

            {
              link: "Gallery",
              whereTo: "/gallery"
            }
        ]
    },
    {
        title: <FormattedMessage id="f9"  />,
        links : [
            {
              link: "All courses",
              whereTo: "/ourCourses"
            },

            {
              link: "Programming for kids",
              whereTo: "/"
            },

            {
              link: "UI/UX Design for kids",
              whereTo: "/"
            },

            {
              link: "Science for Kids",
              whereTo: "/"
            },

            {
              link: "Drawing & Arts",
              whereTo: "/"
            },

            {
              link: "Robotics for Kids",
              whereTo: "/"
            },

            {
              link: "Free courses",
              whereTo: "/"
            }
        ]
    },
    {
        // title: <FormattedMessage id="f18" />,
        title: "Our Services",
        links : [
            // {
            //     link: <FormattedMessage id="f18" />
            //   },
            //   {
            //     link: <FormattedMessage id="f19" />
            //   },
            //   {
            //     link: <FormattedMessage id="f20" />
            //   },
            //   {
            //     link: <FormattedMessage id="f21" />
            //   },
            //   {
            //     link: <FormattedMessage id="f22" />
            //   },
            //   {
            //     link: <FormattedMessage id="f23" />
            //   },
            //   {
            //     link: <FormattedMessage id="f24" />
            //   },
            //   {
            //     link: <FormattedMessage id="f25" />
            //   }

            {
              link: "Daycare",
              whereTo: "/dayCare"
            },

            {
              link: "Weekend stay ",
              whereTo: "/weekEndStay"
            },

            {
              link: "Night stay",
              whereTo: "/nightStay"
            },

            {
              link: "Half day care",
              whereTo: "/halfDayCare"
            },

            {
              link: "Outdoor activities",
              whereTo: "/"
            },

            {
              link: "Stay after regular schedule",
              whereTo: "/"
            }
        ]
    },
    {
        // title: <FormattedMessage id="f27" />,
        title: "More",
        links : [
            // {
            //     link: <FormattedMessage id="f28" />
            //   },
            //   {
            //     link: <FormattedMessage id="f29" />
            //   },
            //   {
            //     link: <FormattedMessage id="f30" />
            //   },
            //   {
            //     link: <FormattedMessage id="f31" />,
            //     whereTo: "/blog"
            //   },
            //   {
            //     link: <FormattedMessage id="f32" />
            //   },
            //   {
            //     link: <FormattedMessage id="f33" />
            //   },
            //   {
            //     link: <FormattedMessage id="f34" />
            //   },
            //   {
            //     link: <FormattedMessage id="f35" />
            //   }

            {
              link: "Contact us",
              whereTo: "/contactUs"
            },

            {
              link: "Apply Now ",
              whereTo: "/apply"
            },

            {
              link: "Blog",
              whereTo: "/blog"
            },

            {
              link: "Donate",
              whereTo: "/donate"
            },

            {
              link: "Shop",
              whereTo: "/"
            },

            {
              link: "Careers",
              whereTo: "/"
            }
        ]
    }
]