import React from 'react'
import './OurCenterGallery.scss'

const OurCenterGallery = () => {
  return (
    <div className='OurCenterGallery'>
        
        <div className="wwoUp">
            <p>
            Our 
            <span className="offerRed">&nbsp;Gallery</span>
            </p>
        </div>

        <div className="OurCenterGallery__inside">
            <div className="OurCenterGalleryImages OurCenterGallery__image1"></div>
            <div className="OurCenterGalleryImages OurCenterGallery__image2"></div>
            <div className="OurCenterGalleryImages OurCenterGallery__image3"></div>
            <div className="OurCenterGalleryImages OurCenterGallery__image4"></div>
            <div className="OurCenterGalleryImages OurCenterGallery__image5"></div>
            <div className="OurCenterGalleryImages OurCenterGallery__image6"></div>
            <div className="OurCenterGalleryImages OurCenterGallery__image7"></div>
            <div className="OurCenterGalleryImages OurCenterGallery__image8"></div>
        </div>
        
    </div>
  )
}

export default OurCenterGallery