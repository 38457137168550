import React, { useState } from 'react'
import './DonationDetails.scss'
import Amount from './Amount/Amount';
import { Link } from 'react-router-dom';
import ContactDetails from './ContactDetails/ContactDetails';
import Payment from './Payment/Payment';
import Confirmation from './Confirmation/Confirmation';
import { IoIosArrowBack } from 'react-icons/io';


const DonationDetails = () => {
    const [amount, setAmount] = useState(true);
    const [contact, setContact] = useState(false);
    const [payment, setPayment] = useState(false);
    const [confirm, setConfirm] = useState(false);


  return (
    <div className='DonationDetails'>
        <div className="DonationDetails__top">
            <div className="left">
                <div className="donationImage"></div>
                <div className="firstBorder"></div>
                <div className="secondBorder"></div>
            </div>

            <div className="right">
                <div className="kkclD makeFontBiger">
                    <p>
                    Donate
                    </p>
                </div>

                <div className='kkcHeading'>
                   <p>
                        Make a&nbsp;
                        <span className='yellowA'>
                        donation
                        </span>
                    </p>
                </div>

                <div className="kkclD">
                    <p>
                    With your kind donation, we will be able to assist young children who have difficulty affording an education in getting the same education as their peers.
                    </p>
                </div>
            </div>
        </div>


        <div className="DonationDetails__mid">
            <div className="top">
                <div className='amount' onClick={() => {
                        setAmount(!amount)
                        setContact(false)
                        setPayment(false)
                        setConfirm(false)
                    }}>
                    <div className={`circleIcon ${amount ? "show" : ""}`} ></div>
                    <p className="regularText">
                    Amount
                    </p>
                </div>

                <div className='contact'>
                    <div className={`circleIcon ${contact ? "show": ""}`}></div>
                    <p className="regularText">
                    Contact Details
                    </p>
                </div>

                <div className='payment'>
                    <div className={`circleIcon ${payment ? "show": ""} `}></div>
                    <p className="regularText">
                    Payment Details
                    </p>
                </div>

                <div className='confirmation'>
                    <div className={`circleIcon ${confirm ? "show": ""} `}></div>
                    <p className="regularText">
                    Confirmation
                    </p>
                </div>
            </div>


            <div className="bottom">
                {
                    amount && (
                        <>
                            <Amount />
                            <Link 
                                className='amounButton'
                                onClick={() => {
                                    setAmount(false);
                                    setContact(true)
                                }}
                            >
                                Next
                            </Link>
                        </>
                    )
                }

                {
                    contact && (
                        <>
                            <ContactDetails />
                            <div className='makeInLine'>
                                <Link 
                                    className='backTo'
                                    onClick={() => {
                                        setAmount(true);
                                        setContact(false)
                                    }}
                                >
                                    <IoIosArrowBack />
                                    Back
                                </Link>

                                <Link 
                                    className='amounButton'
                                    onClick={() => {
                                        setContact(false)
                                        setPayment(true);
                                    }}
                                >
                                    Next
                                </Link>
                            </div>
                        </>
                    )
                }


                {
                    payment && (
                        <>
                            <Payment />
                            <div className='makeInLine'>
                                <Link 
                                    className='backTo'
                                    onClick={() => {
                                        setPayment(false)
                                        setContact(true)
                                    }}
                                >
                                    <IoIosArrowBack />
                                    Back
                                </Link>

                                <Link 
                                    className='amounButton'
                                    onClick={() => {
                                        setPayment(false)
                                        setConfirm(true);
                                    }}
                                >
                                    Give Now
                                </Link>
                            </div>
                        </>
                    )
                }

                {
                    confirm && (
                        <>
                            <Confirmation />
                            <Link 
                                className='amounButton'
                                onClick={() => {
                                    setConfirm(false)
                                }}
                            >
                                Thanks
                            </Link>
                        </>
                    )
                }


            </div>

        </div>







        <div className="DonationDetails__bottom">
            <p className='payments'>
            Donate with your preferred payment method:
            </p>

            <div className="right">
                <div className="pay1"></div>
                <div className="pay2"></div>
                <div className="pay3"></div>
                <div className="pay4"></div>
                <div className="pay5"></div>
                <div className="pay6"></div>
            </div>
        </div>
    </div>
  )
}

export default DonationDetails