import medical1 from "../../../assets/images/OurTeam-images/medical1.webp";
import medical2 from "../../../assets/images/OurTeam-images/medical2.webp";
import medical3 from "../../../assets/images/OurTeam-images/medical3.webp";
import medical4 from "../../../assets/images/OurTeam-images/medical4.webp";
import medical5 from "../../../assets/images/OurTeam-images/medical5.webp";
import medical6 from "../../../assets/images/OurTeam-images/medical6.webp";

import social1 from "../../../assets/images/OurTeam-images/facebook.svg";
import social2 from "../../../assets/images/OurTeam-images/instagram.svg";
import social3 from "../../../assets/images/OurTeam-images/snapchat.svg";
import social4 from "../../../assets/images/OurTeam-images/twitter.svg";
import social5 from "../../../assets/images/OurTeam-images/linkedin.svg";
import { FormattedMessage } from "react-intl"

export const ourTeamMedical = [
    {
        circle: "#9066F1",
        person: medical1,
        role : <FormattedMessage id="nurse"/>,
        name: "Klea Ferati",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FEC801",
        person: medical2,
        role : <FormattedMessage id="dentist"/>,
        name: "Anuea Halimi",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FE570B",
        person: medical3,
        role : <FormattedMessage id="doctor"/>,
        name: "Flora Loxha",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#9066F1",
        person: medical4,
        role : <FormattedMessage id="nurse"/>,
        name: "Artiol Limani",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FEC801",
        person: medical5,
        role : <FormattedMessage id="doctor"/>,
        name: "Flakron Bucolli",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FE570B",
        person: medical6,
        role : <FormattedMessage id="nurse"/>,
        name: "Guri Thaci",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    }
]