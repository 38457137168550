import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FormattedMessage } from "react-intl"

const ShopToDonateCard = (props) => {

    const [isCardSaved, setIsCardSaved] = useState(false);
  
    const handleHeartClick = () => {
      // Assuming your props contain all the necessary data for saving the card
      const { id,upperImage, type, heartI, cartI } = props;
  
     const savedCards = JSON.parse(localStorage.getItem('savedCards')) || [];
        const newCard = { id,upperImage, type, heartI, cartI };

    // Check if the card is not already saved
    if (!savedCards.some((card) => card.id === newCard.id)) {
      savedCards.push(newCard);
      localStorage.setItem('savedCards', JSON.stringify(savedCards));

      // Update state to indicate that the card has been saved
      setIsCardSaved(true);
    }
    };


  return (
    <Link className='shtdCard'>
        <div className='upperImageHoldershtd'>
            <div className='upperImageshtd' style={{ content: `url(${props.upperImage})` }}></div>
        </div>
        <div className='shtdCardDown'>
            <div className='shtdCardDown-up'>
               <div className='shtdcdup-left'>
                <p>{props.type}</p>
               </div>
               <div className='shtdcdup-right'>
                <div className={`${isCardSaved ? 'savedColor' : 'heartsh shtdI'}`} onClick={handleHeartClick}  style={{ content: `url(${props.heartI})` }}></div>
                <div className='cartsh shtdI' style={{ content: `url(${props.cartI})` }}></div>
               </div>
            </div>
            <div className='shtdCardDown-do'>
                <div className='shtdCardDown-doc'>
                    <p className='grayText'>
                    <FormattedMessage id="shtdC1"  />
                        </p>
                    <p className='downerText'>Will Blakkee</p>
                </div>
                <div className='shtdCardDown-doc'>
                    <p className='grayText'>  <FormattedMessage id="shtdC2"  /></p>
                    <p className='downerText'>340</p>
                </div>
                <div className='shtdCardDown-doc'>
                    <p className='grayText'>  <FormattedMessage id="shtdC3"  /></p>
                    <p className='downerText'>Cover</p>
                </div>
                <div className='shtdCardDown-doc'>
                    <p className='grayText'>  <FormattedMessage id="shtdC4"  /></p>
                    <p className='downerText'><span className='dollar'>$</span> 12.30</p>
                </div>
            </div>
        </div>
    </Link>
  )
}

export default ShopToDonateCard
