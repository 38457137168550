import React, { useEffect, useState } from "react";
import "./CoursesDetails.scss";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import { IoIosArrowRoundBack, IoIosPricetag } from "react-icons/io";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaClock, FaUser } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { CiUser } from "react-icons/ci";

const CoursesDetails = () => {
  const { id } = useParams();

  const [pageData, setPageData] = useState([]);
  const [pageData2, setPageData2] = useState([]);
  const [suggested, setSuggested] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}OurCourses-en-GB.json`)

      .then((res) => {
        setPageData(res.data);
        const cardName = res.data[id - 1].card;
        const filteredData = res.data.filter((item) => item.card === cardName);
        setSuggested(filteredData);

        setPageData2(res.data[id]);
      })
      .catch((error) => {
        console.error("OurCourses : ", error);
      });
  }, []);

  const item = pageData.find((item) => item.id === Number(id)) || pageData;

  const bgImageDiv = {
    backgroundImage: `url(${process.env.REACT_APP_OurCoursesImages_IMAGE}${item.upperImage})`,
  };

  return (
    <div className="CoursesDetails">
      <Navbar />

      <div className="CoursesDetails__inside">
        <div className="CoursesDetails__1" style={bgImageDiv}>
          <h1>{item.childish}</h1>
          <Link to="/checkOurCourses" className="backInPage">
            <IoIosArrowRoundBack />
            Back To Courses
          </Link>
          <div className="CoursesDetails__1Filter"></div>
        </div>

        <div className="CoursesDetails__2">
        <Link className="enroll">Enroll</Link>

          <h1 className="CoursesDetailsTitle">Course Description</h1>

          <p className="CoursesDetailsText">{item.bigDescription}</p>

          <div className="items">
            <div className="item">
              <FaRegCalendarAlt />

              <div className="right">
                <h4>Duration</h4>

                <p>{item.durationTime}</p>

                <p>{item.sessionPerWeek}</p>
              </div>
            </div>

            <div className="item">
              <FaClock />

              <div className="right">
                <h4>Schedule</h4>

                <p>{item.hoursPerSession}</p>

                <p>{item.starAndEntTime}</p>
              </div>
            </div>

            <div className="item">
              <IoIosPricetag />

              <div className="right">
                <h4>Total Price</h4>
                <p>{item.totalPrice}</p>
              </div>
            </div>

            <div className="item">
              <FaUser />

              <div className="right">
                <h4>Age</h4>

                <p>{item.age}</p>
              </div>
            </div>

            <div className="item">
              <div className="instructor"></div>

              <div className="right">
                <h4>Instructor</h4>

                <p>{item.instructor}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="CoursesDetails__3">
          <h1 className="CoursesDetailsTitle">Curriculum</h1>

          <div className="CurriculumCards">
            {/* Week 1 */}
            {item.curriculum1 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum1}</h3>
                  <p className="whatWeek">{item.curriculum1Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum1Detail__1}</li>
                  <li>{item.curriculum1Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 1 */}

            {/* Week 2 */}
            {item.curriculum2 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum2}</h3>
                  <p className="whatWeek">{item.curriculum2Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum2Detail__1}</li>
                  <li>{item.curriculum2Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 2 */}

            {/* Week 3 */}
            {item.curriculum3 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum3}</h3>
                  <p className="whatWeek">{item.curriculum3Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum3Detail__1}</li>
                  <li>{item.curriculum3Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 3 */}

            {/* Week 4 */}
            {item.curriculum4 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum4}</h3>
                  <p className="whatWeek">{item.curriculum4Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum4Detail__1}</li>
                  <li>{item.curriculum4Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 4 */}

            {/* Week 5 */}
            {item.curriculum5 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum5}</h3>
                  <p className="whatWeek">{item.curriculum5Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum5Detail__1}</li>
                  <li>{item.curriculum5Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 5 */}

            {/* Week 6 */}
            {item.curriculum6 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum6}</h3>
                  <p className="whatWeek">{item.curriculum6Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum6Detail__1}</li>
                  <li>{item.curriculum6Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 6 */}

            {/* Week 7 */}
            {item.curriculum7 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum7}</h3>
                  <p className="whatWeek">{item.curriculum7Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum7Detail__1}</li>
                  <li>{item.curriculum7Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 7 */}

            {/* Week 8 */}
            {item.curriculum8 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum8}</h3>
                  <p className="whatWeek">{item.curriculum8Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum8Detail__1}</li>
                  <li>{item.curriculum8Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 8 */}

            {/* Week 9 */}
            {item.curriculum9 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum9}</h3>
                  <p className="whatWeek">{item.curriculum9Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum9Detail__1}</li>
                  <li>{item.curriculum9Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 9 */}

            {/* Week 10 */}
            {item.curriculum10 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum10}</h3>
                  <p className="whatWeek">{item.curriculum10Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum10Detail__1}</li>
                  <li>{item.curriculum10Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 10 */}

            {/* Week 11 */}
            {item.curriculum11 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum11}</h3>
                  <p className="whatWeek">{item.curriculum11Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum11Detail__1}</li>
                  <li>{item.curriculum11Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 11 */}

            {/* Week 12 */}
            {item.curriculum12 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum12}</h3>
                  <p className="whatWeek">{item.curriculum12Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum12Detail__1}</li>
                  <li>{item.curriculum12Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 12 */}
          </div>
        </div>

        <div className="CoursesDetails__4">
          <h1 className="CoursesDetailsTitle">Teaching Methodology</h1>

          <div className="TeachingCards">
            <div className="TeachingCard">
              <div className="TeachingCardIcon TeachingCardIcon1"></div>
              <p className="TeachingCardTitle">Interactive Learning:</p>
              <p className="TeachingCardText">
                Utilizing platforms like Scratch to make coding visual and
                engaging for kids to understand easier.
              </p>
            </div>

            <div className="TeachingCard">
              <div className="TeachingCardIcon TeachingCardIcon2"></div>
              <p className="TeachingCardTitle">Project-Based:</p>
              <p className="TeachingCardText">
                Encouraging hands-on learning through projects that allow
                creativity and application of learned concepts.
              </p>
            </div>

            <div className="TeachingCard">
              <div className="TeachingCardIcon TeachingCardIcon3"></div>
              <p className="TeachingCardTitle">Collaborative Environment:</p>
              <p className="TeachingCardText">
                Promoting teamwork through pair programming and group projects.
              </p>
            </div>

            <div className="TeachingCard">
              <div className="TeachingCardIcon TeachingCardIcon4"></div>
              <p className="TeachingCardTitle">Supportive Feedback:</p>
              <p className="TeachingCardText">
                Offering constructive feedback and encouragement to promote a
                positive learning experience.
              </p>
            </div>
          </div>
        </div>

        <div className="CoursesDetails__5">
          <h1 className="CoursesDetailsTitle">
            What to expect from this course?
          </h1>

          <div className="CoursesDetails__5__inside">
            <div className="top">
              <div className="left">
                <p className="whatP">Students will:</p>

                <div className="whatQuestions">
                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder1">
                      <div className="whatImage whatImage1"></div>
                    </div>

                    <div className="whatRight">
                      <p>Gain a Strong Foundation in Programming</p>
                      <span>
                        By the end of the course, students will understand basic
                        programming concepts and how to apply them to create
                        their own projects
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder2">
                      <div className="whatImage whatImage2"></div>
                    </div>

                    <div className="whatRight">
                      <p>
                        Develop Critical Thinking and Problem-Solving Skills:
                      </p>
                      <span>
                        Through coding challenges and debugging activities,
                        students will learn to think logically and solve
                        problems creatively.
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder3">
                      <div className="whatImage whatImage3"></div>
                    </div>

                    <div className="whatRight">
                      <p>Boost Digital Literacy:</p>
                      <span>
                        Familiarity with the computer environment and software
                        tools will empower students in their academic and
                        personal lives.
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder4">
                      <div className="whatImage whatImage4"></div>
                    </div>

                    <div className="whatRight">
                      <p>Enjoy a Supportive and Fun Learning Environment:</p>
                      <span>
                        The course is designed to be engaging and encouraging,
                        ensuring students feel confident and motivated
                        throughout their learning journey.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right">
                <div className="topRightWave"></div>
                <div className="topRightStar"></div>
                <div className="topRightCircle"></div>
              </div>
            </div>

            <div className="top bottom">
              <div className="left">
                <p className="whatP">Parents can expect:</p>

                <div className="whatQuestions">
                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder5">
                      <div className="whatImage whatImage5"></div>
                    </div>

                    <div className="whatRight">
                      <p>Regular Updates and Involvement Opportunities:</p>
                      <span>
                        Through orientation sessions, weekly updates, and a
                        feedback mechanism, parents will be well-informed and
                        involved in their child's learning process.
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder6">
                      <div className="whatImage whatImage6"></div>
                    </div>

                    <div className="whatRight">
                      <p>A Safe and Nurturing Space for Learning:</p>
                      <span>
                        Qualified instructors and a carefully designed
                        curriculum ensure a positive and productive learning
                        environment for all students.
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder7">
                      <div className="whatImage whatImage7"></div>
                    </div>

                    <div className="whatRight">
                      <p>Visible Progress and Development:</p>
                      <span>
                        Parents will witness their child's growth in technical
                        skills, creativity, and confidence as they progress
                        through the course.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right">
                <div className="bottomRightmagnet"></div>
                <div className="bottomRightSquare"></div>
                <div className="bottomRightWave"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="CoursesDetails__6">
          <h1 className="CoursesDetailsTitle">Register now</h1>

          <p className="uppercaseText">
            INFO ABOUT PARENT OR AUTHORIZED PERSON
          </p>

          <div className="CoursesDetails__6__inside">
            <div className="ContactDetails Amount">
              <div className="Amount__6">
                <div className="insideAmount">
                  <div>
                    <label htmlFor="firstName">First name</label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <CiUser />
                      </span>
                      <input
                        type="text"
                        id="firstName"
                        placeholder="Enter your first name"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="lastName">Last Name</label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <CiUser />
                      </span>
                      <input
                        type="text"
                        id="lastName"
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="Amount__6">
                <div className="insideAmount">
                  <div>
                    <label htmlFor="phoneNumber">Phone number</label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <FiPhone />
                      </span>
                      <input
                        type="number"
                        id="phoneNumber"
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email">Email</label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <MdOutlineEmail />
                      </span>
                      <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <p className="uppercaseText">INFO ABOUT THE CHILD</p>

              <div className="Amount__6">
                <div className="insideAmount">
                  <div>
                    <label htmlFor="firstName2">First name</label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <CiUser />
                      </span>
                      <input
                        type="text"
                        id="firstName2"
                        placeholder="Enter your first name"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="lastName2">Last Name</label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <CiUser />
                      </span>
                      <input
                        type="text"
                        id="lastName2"
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Link className="enroll">Enroll</Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CoursesDetails;
