import icon1 from "../../../assets/images/Banner-images/cicon1.svg";
import icon2 from "../../../assets/images/Banner-images/cicon2.svg";
import icon3 from "../../../assets/images/Banner-images/cicon3.svg";
import icon4 from "../../../assets/images/Banner-images/cicon4.svg";
import { FormattedMessage } from "react-intl"

export const bannerData = [
    {
        icon : icon1,
        title : <FormattedMessage id="ban5-1-1"  />,
        description : <FormattedMessage id="ban5-1-2"  />
    },
    {
        icon : icon2,
        title : <FormattedMessage id="ban5-2-1"  />,
        description : <FormattedMessage id="ban5-2-2"  />
    },
    {
        icon : icon3,
        title : <FormattedMessage id="ban5-3-1"  />,
        description : <FormattedMessage id="ban5-3-2"  />
    },
    {
        icon : icon4,
        title : <FormattedMessage id="ban5-4-1"  />,
        description : <FormattedMessage id="ban5-4-2"  />
    }
]