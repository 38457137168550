import React from 'react'
import './OurTeam.scss';
import { Link } from 'react-router-dom';
import { ourTeam } from './OurTeamData';
import { FormattedMessage } from "react-intl"

const OurTeam = () => {


  return (
    <div className='ourTeam'>
        <div className='eventsActivities-heading'>
        <p><FormattedMessage id="ot1"/> <span className='yellowA'><FormattedMessage id="ot2"/></span></p>
      </div>

        <div className='ourTeamCards'>
            {
                ourTeam.map((ot,index) => {
                    return(
                        <Link className='ourTeamCard' key={index}>
                            <div className='circleBgOt' style={
                                {
                                     backgroundColor: `${ot.circle}`, 
                                }} 
                            ></div>
                            <div className='personImgHolder'>
                                <div className='personImg' style={{ content: `url(${ot.person})` }} ></div>
                            </div>
                            <div className='personRole'>
                                <p>{ot.role}</p>
                            </div>
                            <div className='personName'>
                                <p>{ot.name}</p>
                            </div>
                            <div className='otCardSocials'>
                                    {
                                        ot.socials.map((ots , index2) =>{
                                            return (
                                                <Link className='otsocial' style={{ content: `url(${ots.social})` }} key={index2}></Link>
                                            )
                                        })
                                    }
                            </div>
                        </Link>
                    )
                })
            }
        </div>


         <div className='currButtonHolder'>
                <div className='currButton button'>
                <Link to="ourteam" ><FormattedMessage id="centersLink"/></Link>
                </div>
        </div>

    </div>
  )
}

export default OurTeam
