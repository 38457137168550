import React from 'react'
import "./FlexibileSol.scss";
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl"

const FlexibileSol = () => {
  return (
    <div className='flexibile'>
        <div className='leftFlexi'>
            <div className='childFlex'></div>
        </div>
        <div className='middleFlexi'>
            <div className='flexiHeader'>
                <p>
                <FormattedMessage id="flexi1"  />
                    </p>
            </div>
            <div className='flexiText'>
                <p>
                <FormattedMessage id="flexi2"  />
                    </p>
            </div>
            <div className='flexiButton button'>
                <Link>
                <FormattedMessage id="flexi3L"  />
                </Link>
            </div>
        </div>
        <div className='rightFlexi'>
            <div className='yellowContainer'></div>
        </div>
    </div>
  )
}

export default FlexibileSol
