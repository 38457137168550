import { FormattedMessage } from "react-intl"

export const tabData = [
    {
        city: <FormattedMessage id="tab1-1"  />
    },
    {
        city: "Prishtina"
    },
    {
        city: "Peja"
    },
    {
        city: "Prizeren"
    },
    {
        city: "Podujeve"
    },
    {
        city: "Gjilan"
    },
    {
        city: "Gjakove"
    },
    {
        city: "Ferizaj"
    },
    {
        city: "Mitrovice"
    }
]