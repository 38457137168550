import social1 from '../../../assets/images/Footer-images/facebook.svg';
import social2 from '../../../assets/images/Footer-images/instagram.svg';
import social3 from '../../../assets/images/Footer-images/snapchat.svg';
import social4 from '../../../assets/images/Footer-images/twitter.svg';
import social5 from '../../../assets/images/Footer-images/linkedin.svg';


export const footerSocials = [
    {
        to : "https://www.facebook.com/kosovakidscom",
        social: social1
    },
    { 
        to : "https://www.instagram.com/kosovakids/",
        social: social2
    },
    {
        to: "",
        social: social3
    },
    {
        to: "",
        social: social4
    },
    {
        to: "",
        social: social5
    }
]