import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import CoursesCard from "./CoursesCard";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 },
];

const CoursesTabPanel = ({ tabName }) => {
  const [specificData, setSpecificData] = useState([]);
  const [dataOneItem, setDataOneItem] = useState(false);

  const fetchData = async () => {
    try {
      const storedLocale = localStorage.getItem("locale");
      const supportedLocales = ["en-GB", "sq-AL"];
      const defaultLocale = "en-GB";

      const locale = supportedLocales.includes(storedLocale)
        ? storedLocale
        : defaultLocale;
      const api = `/API/OurCourses-${locale}.json`;

      const response = await fetch(api);
      const data = await response.json();

      const filteredData =
        tabName === "All"
          ? data
          : data.filter((item) => item.category === tabName);

      setSpecificData(filteredData);
      setDataOneItem(filteredData.length === 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [localStorage.getItem("language")]);

  const carouselRef = React.useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };

  return (
    <div className="centerTabPanel">
      <Carousel
        itemPadding={[10, 15]}
        className={`centerCarousel ${dataOneItem ? "centerCarousel1" : ""}`}
        breakPoints={breakPoints}
        ref={carouselRef}
        onPrevStart={onPrevStart}
        onNextStart={onNextStart}
        disableArrowsOnEnd={false}
      >
        {specificData.map((s) => (
          <CoursesCard
            key={s.id} // Add key prop for optimization
            upperImage={`${process.env.REACT_APP_OurCoursesImages_IMAGE}${s.upperImage}`}
            ccardText={s.ccardText}
            ageGroup={s.ageGroup}
            location={s.location}
            policy={s.policy}
            locationIcon={s.locationIcon}
            paid={s.paid}
            childish={s.childish}
            bgColor={s.bgColor}
            id={s.id}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default CoursesTabPanel;
