import React from 'react'
import './Confirmation.scss'

const Confirmation = () => {
  return (
    <div className='Confirmation'>
      <h1>
      Thank you very much for your donation
      </h1>
    </div>
  )
}

export default Confirmation
