import directors1 from "../../../assets/images/OurTeam-images/directors1.webp";
import directors2 from "../../../assets/images/OurTeam-images/directors2.webp";
import directors3 from "../../../assets/images/OurTeam-images/directors3.webp";
import directors4 from "../../../assets/images/OurTeam-images/directors4.webp";
import directors5 from "../../../assets/images/OurTeam-images/directors5.webp";
import directors6 from "../../../assets/images/OurTeam-images/directors6.webp";

import social1 from "../../../assets/images/OurTeam-images/facebook.svg";
import social2 from "../../../assets/images/OurTeam-images/instagram.svg";
import social3 from "../../../assets/images/OurTeam-images/snapchat.svg";
import social4 from "../../../assets/images/OurTeam-images/twitter.svg";
import social5 from "../../../assets/images/OurTeam-images/linkedin.svg";
import { FormattedMessage } from "react-intl"

export const ourTeamDirectors = [
    {
        circle: "#9066F1",
        person: directors1,
        role : <FormattedMessage id="ceo"/>,
        name: "Arta Behrami",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FEC801",
        person: directors2,
        role : <FormattedMessage id="ceo"/>,
        name: "Albin Hajzeri",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FE570B",
        person: directors3,
        role : <FormattedMessage id="cao"/>,
        name: "Daors Lahu",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#9066F1",
        person: directors4,
        role : <FormattedMessage id="ce"/>,
        name: "Endrita Shala",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FEC801",
        person: directors5,
        role : <FormattedMessage id="socialMedia"/>,
        name: "Drinor Shabani",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    },
    {
        circle: "#FE570B",
        person: directors6,
        role : <FormattedMessage id="hrMenager"/>,
        name: "Drita Elezi",
        socials : [
            {
                social: social1
            },
            {
                social: social2
            },
            {
                social: social3
            },
            {
                social: social4
            },
            {
                social: social5
            }
        ]
    }
]