import React, { useEffect, useState } from 'react'
import './BlogPage.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import BlogCard from '../../components/pages/Blog/BlogCard'
import axios from 'axios'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { IoIosSearch } from 'react-icons/io'
import { MdKeyboardArrowDown, MdSort } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { FaArrowLeftLong , FaArrowRightLong } from "react-icons/fa6";

const BlogPage = () => {

    const [pageData, setPageData] = useState([])
    const [originalPageData, setOriginalPageData] = useState([]);

    const [pageNews, setPageNews] = useState([])
    const [pageCenters, setPageCenters] = useState([])
    const [pageCourses, setPageCourses] = useState([])
    const [pageBabies, setPageBabies] = useState([])
    const [pageTips, setPageTips] = useState([])
    const [pageParenting, setPageParenting] = useState([])
    const [pagehealthy, setPagehealthy] = useState([])
    const [pageActivities, setPageActivities] = useState([])
    const [perPageNumber, setPerPageNumber] = useState(9);

    const [sortOrder, setSortOrder] = useState("az");


    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_ROUTE}Blog.json`)
            .then((res) => {
                setPageData(res.data)
                setOriginalPageData(res.data);


                setPageNews(res.data.filter((item) => item.card === 'News'));
                setPageCenters(res.data.filter((item) => item.card === 'Centers'));
                setPageCourses(res.data.filter((item) => item.card === 'Courses'));
                setPageBabies(res.data.filter((item) => item.card === 'Babies&Toddlers'));
                setPageTips(res.data.filter((item) => item.card === 'Tips'));
                setPageParenting(res.data.filter((item) => item.card === 'Parenting'));
                setPagehealthy(res.data.filter((item) => item.card === 'Staying healthy'));
                setPageActivities(res.data.filter((item) => item.card === 'Activities'));
            }) 
            .catch((error) => {
                console.error("Blog : ", error)
            });
    }, [])


    const [searchTerm, setSearchTerm] = useState("");
    const handleSearch = (event) => {
      const term = event.target.value.toLowerCase();
      setSearchTerm(term);
      const filteredData = originalPageData.filter((item) =>
        item.title.toLowerCase().includes(term)
      );
      setPageData(filteredData);
    };

    // Sort
    const handleSort = (property) => {
        const sortedData = [...pageData].sort((a, b) => {
          if (sortOrder === "az") {
            return a[property] > b[property] ? 1 : -1;
          } else {
            return a[property] < b[property] ? 1 : -1;
          }
        });
    
        setPageData(sortedData);
        setSortOrder(sortOrder === "az" ? "za" : "az");
      };
    // End Sort
   

    // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = perPageNumber;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const npage = Math.ceil(pageData.length / recordsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const numbersToShow = 5;
  const totalPages = npage;

  const getPageNumbers = () => {
    const midNumber = Math.floor(numbersToShow / 2);
    let startPage = Math.max(1, currentPage - midNumber);
    let endPage = Math.min(startPage + numbersToShow - 1, totalPages);

    if (endPage - startPage + 1 < numbersToShow) {
      startPage = Math.max(1, endPage - numbersToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };



  const filteredAndPaginatedData = pageData.slice(
    (currentPage - 1) * perPageNumber,
    currentPage * perPageNumber
  );

  const filteredAndPaginatedData2 = pageNews.slice(
    (currentPage - 1) * perPageNumber,
    currentPage * perPageNumber
  );



  return (
    <div className='BlogPage'>
        <Navbar />    


            <div className="BlogPage__inside">
                <div className="BlogPage__top">
                    <div className="BlogPage__filter"></div>
                    <div className="blogpage__star"></div>
                    <div className="blogpage__wave"></div>
                    <div className="blogpage__circle"></div>

                    <h1>
                    Read our blog
                    </h1>

                    <p>
                    Read the latest articled related to KosovaKids Centers, news, activities and more. Discover tips related to kids and parents.
                    </p>
                </div>


                <div className="BlogPage__bottom">
                    <h1 className='articleH1'>
                    Articles
                    </h1>

                    <Tabs className="blogpage__tabs">
                        <TabList className="blogpage__tablist">
                            <div className='tab__top__blogpage'>
                                <Tab className="blogpage__tab">All</Tab>
                                <Tab className="blogpage__tab">News</Tab>
                                <Tab className="blogpage__tab">Centers</Tab>
                                <Tab className="blogpage__tab">Courses</Tab>
                                <Tab className="blogpage__tab">Babies&Toddlers</Tab>
                                <Tab className="blogpage__tab">Tips</Tab>
                                <Tab className="blogpage__tab">Parenting</Tab>
                                <Tab className="blogpage__tab">Staying healthy</Tab>
                                <Tab className="blogpage__tab">Activities</Tab>
                            </div>

                            <div className='inside__tab'>
                                <div className="blogpage__search">
                                    <span>
                                        <IoIosSearch />
                                    </span>
                                    <input 
                                        type="search"
                                        placeholder='Search blog'
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                </div>

                                <div className="blogpage__sortby">
                                    <span>
                                        <MdSort />
                                    </span>
                                        Sort by
                                    <span className="arrow">
                                        <MdKeyboardArrowDown />
                                    </span>

                                    <div className="openOnHover__sortBy"
                                        onClick={() => handleSort("title")}
                                    >
                                        <p className={`${sortOrder ? "backColor" : ""}`}>A - Z</p>
                                        <p className={`${!sortOrder ? "backColor" : ""}`}>Z - A</p>
                                    </div>
                                </div>
                            </div>
                        </TabList>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pageData.length > 0 
                                    ? 
                                    (
                                    
                                        filteredAndPaginatedData.map((item) => (
                                            <Link 
                                                to={
                                                    { 
                                                    pathname: `/blogDetails/${item.id}`, 
                                                    state: { featuredData: item } 
                                                    }
                                                }
                                            >
                                                <BlogCard 
                                                    cardColor={item.cardColor}
                                                    blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                                    timeWhen={item.timeWhen}
                                                    card={item.card}
                                                    title={item.title}
                                                />
                                            </Link>
                                        ))
                                    )
                                    :
                                    (
                                        <p className="noResults">No Results found</p>
                                    )
                                }
                            </div>

                            {pageData.length > 0 ? (
                                <div className="bottomEducation__right__bottom">
                                    <ul className="bottom__numbers">
                                        <p onClick={prePage} className='arrowPagination'>
                                        <FaArrowLeftLong />
                                        </p>

                        

                                    {getPageNumbers().map((n) => (
                                        <li
                                        key={n}
                                        className={`page-item ${
                                            currentPage === n ? "activeBottom" : ""
                                        }`}
                                        >
                                        <Link
                                            to="#"
                                            onClick={() => changeCPage(n)}
                                            className="page-link"
                                        >
                                            {n}
                                        </Link>
                                        </li>
                                    ))}

                                    <p onClick={nextPage} className='arrowPagination'>
                                        
                                    <FaArrowRightLong />
                                    </p>
                                    </ul>
                                </div>
                            ) : (
                            <></>
                            )}
                        </TabPanel>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pageNews.length > 0 
                                    ? 
                                    (
                                    
                                        filteredAndPaginatedData2.map((item) => (
                                            <BlogCard 
                                                cardColor={item.cardColor}
                                                blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                                timeWhen={item.timeWhen}
                                                card={item.card}
                                                title={item.title}
                                            />
                                        ))
                                    )
                                    :
                                    (
                                        <p className="noResults">No Results found</p>
                                    )
                                }
                            </div>

                            {!pageNews.length > 0 ? (
                                <div className="bottomEducation__right__bottom">
                                    <ul className="bottom__numbers">
                                        <p onClick={prePage} className='arrowPagination'>
                                        <FaArrowLeftLong />
                                        </p>

                        

                                    {getPageNumbers().map((n) => (
                                        <li
                                        key={n}
                                        className={`page-item ${
                                            currentPage === n ? "activeBottom" : ""
                                        }`}
                                        >
                                        <Link
                                            to="#"
                                            onClick={() => changeCPage(n)}
                                            className="page-link"
                                        >
                                            {n}
                                        </Link>
                                        </li>
                                    ))}

                                    <p onClick={nextPage} className='arrowPagination'>
                                        
                                    <FaArrowRightLong />
                                    </p>
                                    </ul>
                                </div>
                            ) : (
                            <></>
                            )}
                        </TabPanel>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pageCenters.map((item) => (
                                        <BlogCard 
                                            cardColor={item.cardColor}
                                            blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                            timeWhen={item.timeWhen}
                                            card={item.card}
                                            title={item.title}
                                        />
                                    ))
                                }
                            </div>
                        </TabPanel>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pageCourses.map((item) => (
                                        <BlogCard 
                                            cardColor={item.cardColor}
                                            blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                            timeWhen={item.timeWhen}
                                            card={item.card}
                                            title={item.title}
                                        />
                                    ))
                                }
                            </div>
                        </TabPanel>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pageBabies.map((item) => (
                                        <BlogCard 
                                            cardColor={item.cardColor}
                                            blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                            timeWhen={item.timeWhen}
                                            card={item.card}
                                            title={item.title}
                                        />
                                    ))
                                }
                            </div>
                        </TabPanel>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pageTips.map((item) => (
                                        <BlogCard 
                                            cardColor={item.cardColor}
                                            blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                            timeWhen={item.timeWhen}
                                            card={item.card}
                                            title={item.title}
                                        />
                                    ))
                                }
                            </div>
                        </TabPanel>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pageParenting.map((item) => (
                                        <BlogCard 
                                            cardColor={item.cardColor}
                                            blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                            timeWhen={item.timeWhen}
                                            card={item.card}
                                            title={item.title}
                                        />
                                    ))
                                }
                            </div>
                        </TabPanel>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pagehealthy.map((item) => (
                                        <BlogCard 
                                            cardColor={item.cardColor}
                                            blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                            timeWhen={item.timeWhen}
                                            card={item.card}
                                            title={item.title}
                                        />
                                    ))
                                }
                            </div>
                        </TabPanel>

                        <TabPanel className="blogpage__tabpanel">
                            <div className="insideBottom">
                                {
                                    pageActivities.map((item) => (
                                        <BlogCard 
                                            cardColor={item.cardColor}
                                            blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                            timeWhen={item.timeWhen}
                                            card={item.card}
                                            title={item.title}
                                        />
                                    ))
                                }
                            </div>

                            
                        </TabPanel>
                
                    </Tabs>

                

                </div>

            </div>


                


        <Footer />
    </div>
  )
}

export default BlogPage