import React, { useEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel'
import EaCard from './EaCard';

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 1 },
    { width: 1150, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 2 },
    { width: 1750, itemsToShow: 2 }
  ]

const EaTabPanel = ({ tabName }) => {

  const [specificData, setSpecificData] = useState([]);
  const [data,setData] = useState([])

  const fetchData = async () => {
    try {
      const storedLocale = localStorage.getItem('locale');
      const supportedLocales = ['en-GB', 'sq-AL'];
      const defaultLocale = 'en-GB';
  
      const locale = supportedLocales.includes(storedLocale) ? storedLocale : defaultLocale;
      const api = `/API/EventsActivities-${locale}.json`;
  
      console.log('Selected Locale:', locale);
      console.log('API Endpoint:', api);
  
      const response = await fetch(api);
      const data = await response.json();
      const filteredData = tabName === "All" ? data : data.filter((item) => item.whatIs === tabName);
      
      setSpecificData(filteredData);
      setData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [localStorage.getItem('language')]);

  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };
  return (
      <div className='centerTabPanel'>
      <Carousel
       itemPadding={[10, 15]}
       className="centerCarousel"
       breakPoints={breakPoints}
       ref={carouselRef}
       onPrevStart={onPrevStart}
       onNextStart={onNextStart}
       disableArrowsOnEnd={false}
      >
        
        {
          specificData.map((s) => {
            return(
              <EaCard
               leftImg={s.leftImg}
               title={s.title}
               des={s.des}
               logo={s.logo}
              />
            )
          })
        }

      </Carousel>
    </div>
  )
}

export default EaTabPanel
