import React from 'react'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import WhatPeopleSay from '../../components/pages/WhatPeopleSay/WhatPeopleSay'
import GetOurApps from '../../components/pages/GetOurApps/GetOurApps'
import YouCanLearn from '../../components/pages/YouCanLearn/YouCanLearn'
import DonationDetails from '../../components/pages/DonationDetails/DonationDetails'

const Donate = () => {
  return (
    <div className='Donate'>
        <Navbar />

            <DonationDetails />

            
            <WhatPeopleSay/>
            <GetOurApps/>
            <YouCanLearn/>
        <Footer />
    </div>
  )
}

export default Donate