import cicon1 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon1.svg"
import cicon2 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon2.svg"
import cicon3 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon3.svg"
import cicon4 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon4.svg"
import cicon5 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon5.svg"
import cicon6 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon6.svg"
import cicon7 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon7.svg"
import cicon8 from "../../../../../assets/images/Navbar-images/servicesMegamenu-images/cicon8.svg"

import carouselImg1 from '../../../../../assets/images/Navbar-images/servicesMegamenu-images/carouselItem.webp';


export const servicesData = [
    {
        class: "cardA1",
        bgColor: "#FFEAEA",
        aicon : cicon1,
        title: "Daily Care",
        towher: "dayCare"
    },
    {
        class: "cardA2",
        bgColor: "#FBE9FC",
        aicon : cicon2,
        title: "Stay After Regular Schedule",
    },
    {
        class: "cardA3",
        bgColor: "#FFFAE5",
        aicon : cicon3,
        title: "Weekend Stay",
        towher: "weekEndStay"
    },
    {
        class: "cardA4",
        bgColor: "#E9FCF8",
        aicon : cicon4,
        title: "Medical Care",
    },
    {
        class: "cardA5",
        bgColor: "#F4EFFE",
        aicon : cicon5,
        title: "Night Stay",
        towher: "nightStay"
    },
    {
        class: "cardA6",
        bgColor: "#FFEAEA",
        aicon : cicon6,
        title: "Free Courses",
    },
    {
        class: "cardA7",
        bgColor: "#F8E6FF",
        aicon : cicon7,
        title: "Half Day Care",
        towher: "halfDayCare"
    },
    {
        class: "cardA8",
        bgColor: "#EAF1FF",
        aicon : cicon8,
        title: "Outdoor Activities",
    }
]

export const SCarouselData = [
    {
        carouselImg : carouselImg1,
        title: "Earth Day",
        des : "Let's celebrate Earth Day together in our centers."
    },
    {
        carouselImg : carouselImg1,
        title: "Earth Day",
        des : "Let's celebrate Earth Day together in our centers."
    },
    {
        carouselImg : carouselImg1,
        title: "Earth Day",
        des : "Let's celebrate Earth Day together in our centers."
    }
]