import React from 'react'
import "./OurCourses.scss";
import { Link } from 'react-router-dom';
import { Tab, TabList, Tabs ,TabPanel} from 'react-tabs';
import { tabData } from './TabData';
import 'react-tabs/style/react-tabs.css';
import CoursesTabPanel from './CoursesTabPanel';
import { FormattedMessage } from "react-intl"

const OurCourses = () => {
  return (
    <div className='centers'>
    <div className='centersUp'>
        <p className='ourCoursesP'><span className='lightDark'> <FormattedMessage id="ch1"  /> </span> <span className='offerRed'> <FormattedMessage id="ch2"  /> </span></p>
        <div className='viewAllCentres button'>
            <Link to="/ourCourses"><FormattedMessage id="centersLink"  /></Link>
        </div>
    </div>
    <div>
    <Tabs className="centersTabs">
        <TabList className="centerTablists">
                {
                    tabData.map((t) => {
                        return(
                            <Tab className='tabListCenter button'>
                                <p>{t.city}</p>
                            </Tab>
                        )
                    })
                }
        </TabList>

        <TabPanel>
           <CoursesTabPanel tabName={"All"}/>
        </TabPanel>

        <TabPanel>
           <CoursesTabPanel tabName={"Robotics & IT"}/>
        </TabPanel>

        <TabPanel>
           <CoursesTabPanel tabName={"Graphic Design"}/>
        </TabPanel>

        <TabPanel>
           <CoursesTabPanel tabName={"Science"}/>
        </TabPanel>

        <TabPanel>
           <CoursesTabPanel tabName={"Soft skills"}/>
        </TabPanel>

        <TabPanel>
           <CoursesTabPanel tabName={"All"}/>
        </TabPanel>

       
       

    </Tabs>
    </div>
</div>
  )
}

export default OurCourses
