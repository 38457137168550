import React from 'react'
import './Services.scss';
import { carouselDataC } from '../Courses/CoursesData';
import { SCarouselData, servicesData } from './ServicesData';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel'

const Services = () => {

    const breakPoints = [
        { width: 100, itemsToShow: 1 },
        { width: 1750, itemsToShow: 2 },
    ];

    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo("single-slide".length);
        }
    };
  return (
    <div className='aboutMegamenu'>
    <div className='aboutLeft'>
        <div className='aboutLeftText'>
            <p>SERVICES</p>
        </div>
        <div className='aboutLeftCards'>
            {
                servicesData.map((ab,index) =>{
                    return(
                        <Link className={`aboutMegamenuCard ${ab.class}`} to={`/${ab.towher}`}>
                            <div className='acardHolderImg'>
                                    <div className='acMIco' style={{ content: `url(${ab.aicon})`}}></div>
                            </div>
                            <div className='acardRightContent'>
                                <p className='acardTitle'>{ab.title}</p>
                                <p className='acardText'>{ab.des}</p>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    </div>
    <div className='coursesRight'>
            <div className='aboutRightText'>
                <p>JOIN OUR ACTIVITIES</p>
            </div>
            <Carousel
            itemPadding={[10, 10]}
            className="expCarousel"
            breakPoints={breakPoints}
            ref={carouselRef}
            onPrevStart={onPrevStart}
            onNextStart={onNextStart}
            disableArrowsOnEnd={false}
            >
                    {
                        SCarouselData.map((cd,index) => {
                            return(
                                <div className='coursesSCard' key={index}>
                                    <div className='coursesCardImg' style={{ content: `url(${cd.carouselImg})` }}></div>
                                    <div className='coursesCDown'>
                                       <p className='sTitle'>{cd.title}</p>
                                       <p className='sDes'>{cd.des}</p>
                                    </div>
                                </div>
                            )
                        })
                    }

            </Carousel>

            <div className='shopButton button'>
                <Link>See all activities</Link>
            </div>
    </div>
</div>
  )
}

export default Services
