import React from "react";
import "./ContactDetails.scss";
import { CiUser } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { PiGlobeHemisphereWestFill } from "react-icons/pi";

const ContactDetails = () => {
  return (
    <div className="ContactDetails Amount">
      <div className="Amount__1">
        <p className="mediumText">Your contact details</p>
      </div>

      <div className="Amount__6">
        <div className="insideAmount">
          <div>
            <label htmlFor="firstName">First Name</label>
            <div className="spanAndInput people">
              <span id="people">
                <CiUser />
              </span>
              <input
                type="text"
                id="firstName"
                placeholder="Write first name"
              />
            </div>
          </div>

          <div>
            <label htmlFor="lastName">Last Name</label>
            <div className="spanAndInput people">
              <span id="people">
                <CiUser />
              </span>
              <input type="text" id="lastName" placeholder="Write last name" />
            </div>
          </div>
        </div>

        <div className="hideProfile">
          <input type="checkbox" name="" id="hideProfile" />
          <label htmlFor="hideProfile">Hide my name from the public.</label>
        </div>
      </div>

      <div className="Amount__6">
        <div className="insideAmount">
          <div>
            <label htmlFor="email">Email</label>
            <div className="spanAndInput people">
              <span id="people">
                <MdOutlineEmail />
              </span>
              <input type="email" id="email" placeholder="Email address here" />
            </div>
          </div>

          <div>
            <label htmlFor="phoneNumber">Phone number</label>
            <div className="spanAndInput people">
              <span id="people">
                <FiPhone />
              </span>
              <input
                type="number"
                id="phoneNumber"
                placeholder="Your phone number here"
              />
            </div>
          </div>
        </div>
      </div>







      <div className="Amount__6">
        <div className="insideAmount">
          <div>
            <label htmlFor="Country">Country</label>
            <div className="spanAndInput people">
              <span id="people">
                <PiGlobeHemisphereWestFill />
              </span>
              <input type="email" id="Country" placeholder="Choose Country" />
            </div>
          </div>

          <div>
            <label htmlFor="address">Address</label>
            <div className="spanAndInput people">
              <span id="people">
                <IoLocationOutline />
              </span>
              <input
                type="phone"
                id="address"
                placeholder="Write your address"
              />
            </div>
          </div>
        </div>
      </div>



    <div className="makeSomeGray">
        <input type="checkbox" id="termAndService" />
          <label htmlFor="termAndService">
          By donating, you agree to our 
          <span className="yellowA">
            &nbsp;Terms of Service&nbsp;
          </span>
          and 
          <span className="yellowA">
            &nbsp;Privacy Policy.
          </span>
        </label>
      </div>





    </div>
  );
};

export default ContactDetails;
