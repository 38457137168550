import React from 'react'
import './DayCareCard1.scss'

const DayCareCard1 = ({ bgImage, text1, text2, text3 }) => {
  const bgConst = {
    content: `url(${bgImage})`
  }
  return (
    <div className='DayCareCard1'>
        <div className="DayCareCard1__image" style={bgConst}></div>

        <h1 className="text1">
        {text1}
        </h1>
        <h1 className="text2">
        ({text2})
        </h1>

        <p className="text3">
        {text3}
        </p>
    

    </div>
  )
}

export default DayCareCard1