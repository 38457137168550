import React, { useEffect, useState } from 'react'
import './MobNav.scss';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { socialsNavbar } from '../NavbarData';
import { aboutData } from '../MegaMenus/About/AboutData';
import { coursesData } from '../MegaMenus/Courses/CoursesData';
import { servicesData } from '../MegaMenus/Services/ServicesData';
import { shopData } from '../MegaMenus/Shop/ShopData';
import ChangingMode from '../../ChangingMode';

const MobNav = ({setMobNav}) => {
    const handleClose = (e) => {
        const sidebar = document.querySelector('.mobNavContainer')
        if(e.target === sidebar){
            setMobNav(false)
        }
    }
    const resizing = () => {
        if(window.innerWidth > 1100){
            setMobNav(false)
        }
        
    }

    useEffect(() => {
        resizing();
    
        window.addEventListener('resize', resizing);
    
        return () => {
          window.removeEventListener('resize', resizing);
        };
      }, []);


      const[mobnav1, setMobNav1] = useState(false);
      const[mobnav2, setMobNav2] = useState(false);
      const[mobnav3, setMobNav3] = useState(false);
      const[mobnav4, setMobNav4] = useState(false);

      const toggleMob1 = () => {
        setMobNav1(!mobnav1)
      }
      const toggleMob2 = () => {
        setMobNav2(!mobnav2)
      }
      const toggleMob3 = () => {
        setMobNav3(!mobnav3)
      }
      const toggleMob4 = () => {
        setMobNav4(!mobnav4)
      }

  return (
    <div className='mobNavContainer' onClick={handleClose}>
      <div className='sidebar'>
            <div className='mobNavLogoHolder'>
                <Link to="/" className='mobNavUp'>
                    <div className='mobNavLogo'></div>
                    <p>KosovaKids</p>
                </Link>
                <IoIosCloseCircleOutline className='xxx' onClick={() => setMobNav(false)}/>
            </div>
            <div className='mobNavLinks'>
                <div className='mobNavLink' >
                    <Link>Home</Link>
                </div>
                <div className='mobNavLink mobNavMegamenu' onClick={toggleMob1}>
                  <div className='mobMega'>
                    <Link>About</Link>
                    <IoIosArrowDown className='arrowDownNav'/>
                  </div>
                    {
                        mobnav1 && (
                            <div className='dropDown1 dropDown'>
                                {
                                    aboutData.map((ad,index) => {
                                        return(
                                            <div className='dropDownRendered' key={index}>
                                                <div className='drIcon' style={{ content: `url(${ad.aicon})` }}></div>
                                                <p className='drTittle'>{ad.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }

                </div>
                <div className='mobNavLink mobNavMegamenu' onClick={toggleMob2}>
                <div className='mobMega'>
                    <Link>Courses</Link>
                    <IoIosArrowDown className='arrowDownNav'/>
                  </div>
                  {
                        mobnav2 && (
                            <div className='dropDown1 dropDown'>
                                {
                                    coursesData.map((ad,index) => {
                                        return(
                                            <div className='dropDownRendered' key={index}>
                                                <div className='drIcon' style={{ content: `url(${ad.aicon})` }}></div>
                                                <p className='drTittle'>{ad.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                </div>
                <div className='mobNavLink mobNavMegamenu' onClick={toggleMob3}>
                <div className='mobMega'>
                    <Link>Services</Link>
                    <IoIosArrowDown className='arrowDownNav'/>
                  </div>
                  {
                        mobnav3 && (
                            <div className='dropDown1 dropDown'>
                                {
                                    servicesData.map((ad,index) => {
                                        return(
                                            <div className='dropDownRendered' key={index}>
                                                <div className='drIcon' style={{ content: `url(${ad.aicon})` }}></div>
                                                <p className='drTittle'>{ad.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                </div>
                <div className='mobNavLink mobNavMegamenu' onClick={toggleMob4}>
                <div className='mobMega'>
                    <Link>Shop</Link>
                    <IoIosArrowDown className='arrowDownNav'/>
                  </div>
                  {
                        mobnav4 && (
                            <div className='dropDown1 dropDown'>
                                {
                                    shopData.map((ad,index) => {
                                        return(
                                            <div className='dropDownRendered' key={index}>
                                                <div className='drIcon' style={{ content: `url(${ad.aicon})` }}></div>
                                                <p className='drTittle'>{ad.title}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                </div>
                <div className='mobNavLink'>
                    <Link>Blog</Link>
                </div>
                <div className='mobNavLink'>
                    <Link>Donate</Link>
                </div>
            </div>

            <div className='mobNavMiddle'>
                <div className='heartCart'>
                    <Link to="/favourites" className='navbarHeart'></Link>
                    <Link className='cartNavbar'></Link>
                </div>
            </div>
        <div className='mobNavDown'>
            <Link to="/contactUs" className='contactUs'>
                Contact Us
            </Link>
            <div className='navbarButton1 button'>
                <Link>Apply</Link>
            </div>
            
        </div>

        <div className='mobNavInfo'>
        <div className='topNavbar-info'>
                <div className='envelopeIcon'></div>
                <p>info@kosovakids.com</p>
            </div>
            <div className='topNavbar-info'>
                <div className='phoneIcon'></div>
                <p>+383 (44) 100 200</p>
            </div>
            <ChangingMode/>
        </div>
        <div className='navbarSocials'>
                {
                    socialsNavbar.map((s,index) => {
                        return(
                            <Link className='socialNavbarIcon' style={{ content: `url(${s.social})` }} key={index}></Link>
                        )
                    })
                }
            </div>
      </div>
    </div>
  )
}

export default MobNav
