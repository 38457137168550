import React, { useEffect, useState } from 'react'
import CurriculumCard from './CurriculumCard';
import Carousel from 'react-elastic-carousel'
import './Curriculum.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl"

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 4 },
    { width: 1750, itemsToShow: 4 }
  ]

  
const Curriculum = () => {
    const [data,setData] = useState([])

    const fetchData = async () => {
      try {
        const storedLocale = localStorage.getItem('locale');
        const supportedLocales = ['en-GB', 'sq-AL'];
        const defaultLocale = 'en-GB';
    
        const locale = supportedLocales.includes(storedLocale) ? storedLocale : defaultLocale;
        const api = `/API/Curriculum-${locale}.json`;
    
        console.log('Selected Locale:', locale);
        console.log('API Endpoint:', api);
    
        const response = await fetch(api);
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    useEffect(() => {
      fetchData();
    }, [localStorage.getItem('language')]);
    

  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo("single-slide".length);
    }
  };
  return (
    <div className='curriculum'>
     <div className='centersUp'>
        <p className='ourCoursesP'><span className='lightDark'><FormattedMessage id="curr1"  /></span> <span className='offerRed'><FormattedMessage id="curr2"  /> </span></p>
    </div>

    <div className='curriculumDown'>
    <Carousel
     itemPadding={[10, 15]}
     className="currCarousel"
     breakPoints={breakPoints}
     ref={carouselRef}
     onPrevStart={onPrevStart}
     onNextStart={onNextStart}
     disableArrowsOnEnd={false}
    >
      
      {
        data.map((s) => {
          return(
            <CurriculumCard
              bgImage={`${process.env.REACT_APP_CurriculumImages_IMAGE}${s.bgImage}`}

              ccicon={s.ccicon}
              title={s.title}
              des={s.des}
            />
          )
        })
      }

    </Carousel>
    </div>

    <div className='currButtonHolder'>
        <div className='currButton button'>
          <Link>
          <FormattedMessage id="centersLink"  />
          </Link>
        </div>
    </div>
    </div>
  )
}

export default Curriculum
