import React from 'react'
import './EventsActivities.scss';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import EaTabPanel from './EaTabPanel';
import { FormattedMessage } from "react-intl"

const EventsActivities = () => {
  return (
    <div className='eventsActivities'>
      <div className='eventsActivities-heading'>
        <p><FormattedMessage id="eve1"/> & <span className='yellowA'><FormattedMessage id="eve1-1"/></span></p>
      </div>
      <Tabs className="eventsActivitiesDown">
        <div className='eaTablist'>
        <TabList className="eactbl">
                    <Tab className="eact">
                        <p>
                        <FormattedMessage id="tab1-1"  />
                          </p>
                    </Tab>
                    <Tab className="eact">
                        <p> <FormattedMessage id="evet"/></p>
                    </Tab>
                    <Tab className="eact">
                    <p> <FormattedMessage id="evet2"/></p>
                    </Tab>
            </TabList>
        </div>

        <div className='eaTabPanel'>
            <TabPanel>
                <EaTabPanel tabName={"All"}/>
            </TabPanel>
            <TabPanel>
                <EaTabPanel tabName={"Outdoor Activites"}/>
            </TabPanel>
            <TabPanel>
                <EaTabPanel tabName={"Events"}/>
            </TabPanel>
        </div>
      </Tabs>
    </div>
  )
}

export default EventsActivities
