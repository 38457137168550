import React from 'react'
import './Discount.scss'

const Discount = (props) => {
  return (
    <div className='Discount'>
        
        <div>
            <h1 className='purpleDiscount'>
              {props.discount1}
            </h1>

            <p>
              {props.discount1Description}
            </p>
        </div>

        <div>
            <h1 className='orangeDiscount'>
              {props.discount2}
            </h1>

            <p>
              {props.discount2Description}
            </p>
        </div>

        <div>
            <h1 className='yellowDiscount'>
              {props.discount3}
            </h1>

            <p>
            {props.discount3Description}
            </p>
        </div>

    </div>
  )
}

export default Discount