import React from 'react'
import './Courses.scss';
import { Link } from 'react-router-dom';
import Carousel from 'react-elastic-carousel'
import { carouselData } from '../Shop/ShopData';
import { carouselDataC, coursesData } from './CoursesData';

const Courses = () => {
    const breakPoints = [
        { width: 100, itemsToShow: 1 },
        { width: 1750, itemsToShow: 2 },
    ];

    const carouselRef = React.useRef(null);
    const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo(0);
        }
    };
    const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
            carouselRef.current.goTo("single-slide".length);
        }
    };
  return (
    <div className='aboutMegamenu'>
    <div className='aboutLeft'>
        <div className='aboutLeftText'>
            <p>COURSES</p>
        </div>
        <div className='aboutLeftCards'>
            {
                coursesData.map((ab,index) =>{
                    return(
                        <Link className={`aboutMegamenuCard ${ab.class}`}>
                            <div className='acardHolderImg'>
                                    <div className='acMIco' style={{ content: `url(${ab.aicon})`}}></div>
                            </div>
                            <div className='acardRightContent'>
                                <p className='acardTitle'>{ab.title}</p>
                                <p className='acardText'>{ab.des}</p>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    </div>
    <div className='coursesRight'>
            <div className='aboutRightText'>
                <p>JOIN OUR ACTIVITIES</p>
            </div>
            <Carousel
            itemPadding={[10, 10]}
            className="expCarousel"
            breakPoints={breakPoints}
            ref={carouselRef}
            onPrevStart={onPrevStart}
            onNextStart={onNextStart}
            disableArrowsOnEnd={false}
            >
                    {
                        carouselDataC.map((cd,index) => {
                            return(
                                <div className='coursesSCard' key={index}>
                                    <div className='pApr button'>
                                        <p className='pforKids'>{cd.programming}</p>
                                    </div>
                                    <div className='coursesCardImg' style={{ content: `url(${cd.carouselImg})` }}></div>
                                    <div className='coursesCDown'>
                                        <div className='uppperCoursesCard'>
                                            <p className='cpro'>{cd.programming}</p>
                                            <p className='cpri'>{cd.price}</p>
                                        </div>
                                        <div className='coursesContentDown'>
                                            <div className='ccdl'>
                                                <div className='cprofile' style={{ content: `url(${cd.profile})` }}></div>
                                                <div className='cprofdt'>
                                                    <p className='cprofdtR'>{cd.instructor}</p>
                                                    <p className='cprofdtRN'>{cd.name}</p>
                                                </div>
                                            </div>
                                            <div className='ccdr'>
                                                <div className='clockImg' style={{ content: `url(${cd.clockIcon})` }}></div>
                                                <div className='ccdrR'>
                                                    <p className='csch'>{cd.schedule}</p>
                                                    <p className='ctime'>{cd.time}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

            </Carousel>

            <div className='shopButton button'>
                <Link>See all courses</Link>
            </div>
    </div>
</div>
  )
}

export default Courses
