import React, { useEffect, useState } from 'react'
import './OurCenterDetail.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import OurCenterHeader from '../../components/pages/OurCenterDetail/OurCenterHeader/OurCenterHeader'
import OurCenterAbout from '../../components/pages/OurCenterDetail/OurCenterAbout/OurCenterAbout'
import OurCenterPlanPrograms from '../../components/pages/OurCenterDetail/OurCenterPlanPrograms/OurCenterPlanPrograms'
import FunActivities from '../../components/pages/OurCenterDetail/FunActivities/FunActivities'
import FoodAndCare from '../../components/pages/OurCenterDetail/FoodAndCare/FoodAndCare'
import PricingOffers from '../../components/pages/PricingOffers/PricingOffers'
import Discount from '../../components/pages/OurCenterDetail/Discount/Discount'
import OurCenterGallery from '../../components/pages/OurCenterDetail/OurCenterGallery/OurCenterGallery'
import OurCenterContactUs from '../../components/pages/OurCenterDetail/OurCenterContactUs/OurCenterContactUs'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const OurCenterDetail = (props) => {

  const { id } = useParams();

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ROUTE}OurCenters-en-GB.json`)

      .then((res) => {
        setPageData(res.data);
      })
      .catch((error) => {
        console.error("OurCenters : ", error);
      });
  }, []);

  const item =
  pageData.find((item) => item.id === Number(id)) ||
  pageData;


  return (
    <div className='OurCenterDetail'>
        <Navbar/>

        <OurCenterHeader
          upperImage={`${process.env.REACT_APP_CentersImages_IMAGE}${item.upperImage}`}
          rating={item.rating}
          name={item.name}
          ccardText={item.ccardText}
          ageGroup={item.ageGroup}
          address={item.address}
          number={item.number}
        />

        <OurCenterAbout 
          aboutThisCenter={item.aboutThisCenter}
        />

        <OurCenterPlanPrograms 
          planprogram1={item.planprogram1}
          planprogram2={item.planprogram2}
          planprogram3={item.planprogram3}
          planprogram1Description={item.planprogram1Description}
          planprogram2Description={item.planprogram2Description}
          planprogram3Description={item.planprogram3Description}
        />

        <FunActivities />

        <FoodAndCare 
          healthtMeal1={item.healthtMeal1}
          healthtMeal2={item.healthtMeal2}
          healthtMeal3={item.healthtMeal3}
          healthtMeal4={item.healthtMeal4}
        />

        <PricingOffers/>

        <Discount 
          discount1={item.discount1}
          discount2={item.discount2}
          discount3={item.discount3}
          discount1Description={item.discount1Description}
          discount2Description={item.discount2Description}
          discount3Description={item.discount3Description}
        />

        <OurCenterGallery />

        <OurCenterContactUs 
          address={item.address}
          number={item.number}
        />



        

        <Footer />
    </div>
  )
}

export default OurCenterDetail