import React from 'react'
import './Centers.scss';
import { Link } from 'react-router-dom';
import { Tab, TabList, Tabs ,TabPanel} from 'react-tabs';
import { tabData } from './CenterTabData';
import CenterTabPanel from './CenterTabPanel';
import 'react-tabs/style/react-tabs.css';
import { FormattedMessage } from "react-intl"


const Centers = () => {
  return (
    <div className='centers'>
        <div className='centersUp'>
            <p>
            <FormattedMessage id="centersH"  />
               </p>
            <div className='viewAllCentres button'>
                <Link to="/ourCenters">
                <FormattedMessage id="centersLink"  />
                </Link>
            </div>
        </div>
        <div>
        <Tabs className="centersTabs">
            <TabList className="centerTablists">
                    {
                        tabData.map((t) => {
                            return(
                                <Tab className='tabListCenter button'>
                                    <p>{t.city}</p>
                                </Tab>
                            )
                        })
                    }
            </TabList>

            <TabPanel>
               <CenterTabPanel tabName={"All"}/>
            </TabPanel>

            <TabPanel>
               <CenterTabPanel tabName={"Prishtina"}/>
            </TabPanel>

            <TabPanel>
               <CenterTabPanel tabName={"Peja"}/>
            </TabPanel>

            <TabPanel>
               <CenterTabPanel tabName={"Prizeren"}/>
            </TabPanel>

            <TabPanel>
               <CenterTabPanel tabName={"Podujeve"}/>
            </TabPanel>

            <TabPanel>
               <CenterTabPanel tabName={"Gjilan"}/>
            </TabPanel>

            <TabPanel>
               <CenterTabPanel tabName={"Gjakove"}/>
            </TabPanel>

            <TabPanel>
               <CenterTabPanel tabName={"Ferizaj"}/>
            </TabPanel>

            <TabPanel>
               <CenterTabPanel tabName={"Mitrovice"}/>
            </TabPanel>
           

        </Tabs>
        </div>
    </div>
  )
}

export default Centers
