import React from 'react'
import './About.scss';
import { aboutData } from './AboutData';
import { Link } from 'react-router-dom';

const About = () => {

    console.log('dasdashdusiajpodiahsduaopisdpuhasd' ,aboutData)
  return (
    <div className='aboutMegamenu'>
        <div className='aboutLeft'>
            <div className='aboutLeftText'>
                <p>ABOUT</p>
            </div>
            <div className='aboutLeftCards'>
                {
                    aboutData.map((ab) =>{
                        return(
                            <Link className={`aboutMegamenuCard ${ab.class}`} to={`/${ab.towher}`}>
                                <div className='acardHolderImg'>
                                        <div className='acMIco' style={{ content: `url(${ab.aicon})`}}></div>
                                </div>
                                <div className='acardRightContent'>
                                    <p className='acardTitle'>{ab.title}</p>
                                    <p className='acardText'>{ab.des}</p>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
        <div className='aboutRight'>
                <div className='aboutRightText'>
                    <p>JOIN THE KINDERGARTEN</p>
                </div>
                <div className='aboutRightImgHolder'>
                    <div className='aboutRightImg'></div>
                </div>
                <div className='aboutRightMiddle'>
                    <p>We have our doors open for your children!</p>
                </div>
                <div className='aboutRightDown'>
                    <p>With locations all around Kosovo, KosovaKids provides the greatest services for kids.</p>
                </div>
                <div className='navbarButton1 aboutRightBtn button'>
                    <Link>Enroll Now</Link>
                </div>
        </div>
    </div>
  )
}

export default About
