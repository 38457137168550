import React from 'react'
import "./KosovaKidsCenter.scss"
import { Tab, TabList, Tabs ,TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MdArrowRightAlt } from "react-icons/md";
import { FormattedMessage } from "react-intl"

const KosovaKidsCenter = () => {
  return (
    <Tabs className='kosovaKidsCenter'>
      <div className='kkcLeft'>
            <div className='kkcHeading'>
                <p> <FormattedMessage id="kkc3-1"/> <span className='offerRed'> <FormattedMessage id="kkc3-2"/> </span></p>
            </div>
            <div className='kkctablist'>
                <TabList className="kkctbl">
                    <Tab className="kkct">
                        <p><FormattedMessage id="kkcTab1"/></p>
                    </Tab>
                    <Tab className="kkct">
                    <p><FormattedMessage id="kkcTab2"/></p>
                    </Tab>
                    <Tab className="kkct">
                    <p><FormattedMessage id="kkcTab3"/></p>
                    </Tab>
                </TabList>
            </div>
            <div className='kkclD'>
                   <p><FormattedMessage id="kkc1"/>&nbsp;
                    <span className="changeColor">
                      1 to 5
                    </span>
                   </p>
                   
            </div>
            <div className='learnMore'>
                     <p>
                     <p><FormattedMessage id="kkc2"/></p>
                      </p>
                <div className='arrowCircle'>
                     <MdArrowRightAlt className='aciconar'/>
                </div>
             </div>
      </div>
      <div className='kkcRight'>
        <div className='starYellowkkc kkcicon'></div>
        <div className='starGreenkkc kkcicon'></div>
        <div className='kkcWave kkcicon'></div>
        <div className='kkcMagnet kkcicon'></div>
        <TabPanel>
            <div className='kkcTabPanelImg1'></div>
        </TabPanel>
        <TabPanel>
        <div className='kkcTabPanelImg2'></div>
        </TabPanel>
        <TabPanel>
        <div className='kkcTabPanelImg3'></div>
        </TabPanel>
      </div>
    </Tabs>
  )
}

export default KosovaKidsCenter
