import { FormattedMessage } from "react-intl"

export const tabData = [
    {
        city: <FormattedMessage id="tab1-1"  />
    },
    {
        city: <FormattedMessage id="cTab1"  />
    },
    {
        city: <FormattedMessage id="cTab2"  />
    },
    {
        city: <FormattedMessage id="cTab3"  />
    },
    {
        city: <FormattedMessage id="cTab4"  />
    },
    {
        city: <FormattedMessage id="cTab5"  />
    }
]