import React, { useEffect, useState } from 'react'
import './BlogDetails.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { IoArrowForwardOutline } from "react-icons/io5";
import BlogCard from '../../components/pages/Blog/BlogCard'

const BlogDetails = () => {
    const { id } = useParams();

    const [pageData, setPageData] = useState([]);
    const [pageData2, setPageData2] = useState([]);
    const [suggested, setSuggested] = useState([]);

    useEffect(() => {
        axios
          .get(`${process.env.REACT_APP_API_ROUTE}Blog.json`)
    
          .then((res) => {
            setPageData(res.data);
            const cardName = res.data[id-1].card;
            const filteredData = res.data.filter(item => item.card === cardName);
            setSuggested(filteredData);

            setPageData2(res.data[id]);

        })
          .catch((error) => {
            console.error("OurCenters : ", error);
          });
      }, []);


      console.log(pageData2)

    
      const item =
      pageData.find((item) => item.id === Number(id)) ||
      pageData;

      const bgImageDiv = {
        backgroundImage: `url(${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage})`
      }

    // const item2 = pageData[(item.id + 1) % 21];

    const bgImageDiv2 = {
        // backgroundImage: `url(${process.env.REACT_APP_BlogImage_IMAGE}${pageData2.blogImage})`
    }


    const chnageBorderColor = {
        borderColor: `${item.cardColor}`,
        color: `${item.cardColor}`
    }

  return (
    <div className='BlogDetails'>
        <Navbar />
        
        <div className="BlogDetails__inside">
            <div className="BlogDetails__1" style={bgImageDiv}></div>

            <div className="BlogDetails__2">
                <div className="left">
                    <div className="left1">
                        <div className="topLeft1">
                            <p className="cardBlog" style={chnageBorderColor}>
                                {item.card}
                            </p>

                            <span >
                                {item.timeWhen}
                            </span>
                        </div>

                        <h1 className='bigTitle'>
                            Teaching kids to be grateful for what they have
                        </h1>

                        <div className="underline"></div>

                        <p className='blogDetailsText'>
                        In today's fast-paced world, where consumerism and social media constantly bombard us with messages of inadequacy and desires for more, instilling a sense of gratitude in children has never been more critical. Gratitude is not just about saying "thank you"; it's a deeper appreciation for what one has, leading to increased happiness, reduced stress, and a more fulfilling life. Here's how we can teach our children to embrace gratitude:
                        </p>
                    </div>

                    <div className="left2">
                        <h1 className="blogDetailsTitle">
                            {item.title2}
                        </h1>

                        <p className='blogDetailsText'>
                            {item.description2}
                        </p>
                    </div>

                    <div className="left2">
                        <h1 className="blogDetailsTitle">
                            {item.title3}
                        </h1>

                        <p className='blogDetailsText'>
                            {item.description3}
                        </p>
                    </div>

                    <div className="left2">
                        <h1 className="blogDetailsTitle">
                            {item.title4}
                        </h1>

                        <p className='blogDetailsText'>
                            {item.description4}
                        </p>
                    </div>

                    <div className="left2">
                        <h1 className="blogDetailsTitle">
                            {item.title5}
                        </h1>

                        <p className='blogDetailsText'>
                            {item.description5}
                        </p>
                    </div>

                    <div className="left2">
                        <h1 className="blogDetailsTitle">
                            {item.title6}
                        </h1>

                        <p className='blogDetailsText'>
                            {item.description6}
                        </p>
                    </div>

                    <div className="left2">
                        <h1 className="blogDetailsTitle">
                            {item.title7}
                        </h1>

                        <p className='blogDetailsText'>
                            {item.description7}
                        </p>
                    </div>

                    <div className="left2">
                        <h1 className="blogDetailsTitle">
                            {item.title8}
                        </h1>

                        <p className='blogDetailsText'>
                            {item.description8}
                        </p>
                    </div>

                </div>

                <div className="right">
                    <Link 
                        to={
                            { 
                                pathname: `/blogDetails/${item.id ? item.id !== 25 ? item.id+1 : item.id = 0 : 1}`, 
                            }
                        }
                    >
                        <div className="BlogDetailsCard">
                            <div className="cardImage1" style={bgImageDiv2}></div>

                            <div className="cardText">
                                <h4>
                                NEXT ARTICLE
                                </h4>

                                <p>
                                Five new courses coming to KosovaKids
                                </p>

                                <div className="BlogDetailsCardCircle">
                                    <IoArrowForwardOutline />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>



            <div className="BlogDetails__3">
                <h1 className='bigTitle'>
                    Suggested Articles
                </h1>

                <div className="BlogDetails__3__inside">
                    {
                        suggested.slice(0, 3).map((item) => (
                            <Link 
                                to={
                                    { 
                                    pathname: `/blogDetails/${item.id}`, 
                                    state: { featuredData: item } 
                                    }
                                }
                            >
                                <BlogCard 
                                    cardColor={item.cardColor}
                                    blogImage={`${process.env.REACT_APP_BlogImage_IMAGE}${item.blogImage}`}
                                    timeWhen={item.timeWhen}
                                    card={item.card}
                                    title={item.title}
                                />
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>

        <Footer />
    </div>
  )
}

export default BlogDetails