import React from 'react'
import './AboutUsPage.scss'
import Navbar from '../../components/shared/Navbar/Navbar'
import Footer from '../../components/shared/Footer/Footer'
import YouCanLearn from '../../components/pages/YouCanLearn/YouCanLearn'
import GetOurApps from '../../components/pages/GetOurApps/GetOurApps'
import WhatPeopleSay from '../../components/pages/WhatPeopleSay/WhatPeopleSay'
import OurTeam from '../../components/pages/OurTeam/OurTeam'
import OurGallery from '../../components/pages/OurGallery/OurGallery'
import AboutUs from '../../components/pages/AboutUs/AboutUs'
import KosovaKidsTab from '../../components/pages/KosovaKidsTab/KosovaKidsTab'
import NumbersRising from '../../components/pages/NumbersRising/NumbersRising'

const AboutUsPage = () => {
  return (
    <div className='AboutUsPage'>
        <Navbar />
        <AboutUs />
        <KosovaKidsTab />
        <OurGallery/>
        <OurTeam/>
        <WhatPeopleSay/>
        <GetOurApps/>
        <YouCanLearn />
        <Footer/> 
    </div>
  )
}

export default AboutUsPage