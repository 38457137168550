import React, { useState } from 'react'
import './Amount.scss'
import { CiUser } from "react-icons/ci";
import { MdOutlineEmail } from 'react-icons/md';

const Amount = () => {
    const [openAmount, setOpenAmount] = useState(false)

  return (
    <div className='Amount'>
        
        <div className="Amount__1">
            <p className="mediumText">
            Choose how you want to give
            </p>
            <div className='makePadding'>
                <div>
                    <input type="radio" name='dontaion' id='oneTime'/>
                    <label htmlFor="oneTime">One time donation</label>
                </div>

                <div>
                    <input type="radio" name='dontaion' id='monthlyDonation'/>
                    <label htmlFor="monthlyDonation">Monthly donation</label>
                </div>
            </div>
        </div>


        <div className="Amount__2">
            <p className="mediumText">
            Amount
            </p>

            <div className='makePadding'>
                <p>&euro;20</p>
                <p>&euro;30</p>
                <p>&euro;50</p>
                <p>&euro;80</p>
                <p>&euro;100</p>
            </div>
        </div>

        <div className="Amount__3">
            <div>
                <p className='regularText'>
                Or add your preferred amount:
                </p>
                <p className='regularText'>
                    <span>
                        &euro;
                    </span>
                    Or add your preferred amount:
                </p>
            </div>
        </div>


        <div className="Amount__4" >
            <div className='dedicate'>
                <input 
                    type="checkbox" 
                    id="dedicate"
                    checked={openAmount}    
                />
                    <label htmlFor="dedicate" onClick={() => setOpenAmount(!openAmount)} >
                    Dedicate my donation 
                    <span className='yellowA'>
                        &nbsp;in honor&nbsp;
                    </span>
                    or 
                    <span className='yellowA'> 
                        &nbsp;in memory&nbsp;
                    </span>
                    of someone
                    </label>
            </div>

            <input type="text"/>
        </div>

        {
            openAmount && (
                <div className="Amount__5">
                <p className="mediumText">
                    Type
                    </p>
                    <div className='makePadding'>
                        <div>
                            <input type="radio" name='honor' id='inHonor'/>
                            <label htmlFor="inHonor">In honor of...</label>
                        </div>
        
                        <div>
                            <input type="radio" name='honor' id='inMemory'/>
                            <label htmlFor="inMemory">In memory of...</label>
                        </div>
                    </div>
                </div>
            )
        }

        {
            openAmount && (
                <div className="Amount__6">
                    <div>
                        <label htmlFor="firstName">
                        Honoree's First Name
                        </label>
                        <div className='spanAndInput people'>
                            <span id="people">
                                <CiUser />
                            </span>
                            <input type="text" id='firstName' placeholder='Write first name'/>
                        </div>
                    </div>
        
                    <div>
                        <label htmlFor="lastName">
                        Honoree's Last Name
                        </label>
                        <div className='spanAndInput people'>
                            <span id="people">
                                <CiUser />
                            </span>
                            <input type="text" id='lastName' placeholder='Write last name' />
                        </div>
                    </div>
                </div>
            )
        }

        {
            openAmount && (
                <div className="Amount__7">
                    <div>
                        <label htmlFor="email">
                        Recipient's email
                        </label>
                        <div className='spanAndInput people'>
                            <span id="people">
                            <MdOutlineEmail />
                            </span>
                            <input type="text" id='email' placeholder='Email address here' />
                        </div>
                        <span className='makeSomeGray'>Optionally send a notification email</span>
                    </div>
                </div>
            )
        }

        {
            openAmount && (
                <div className="Amount__8">
                    <div>
                        <label htmlFor="recipientName">
                        Recipient's First Name
                        </label>
                        <div className='spanAndInput people'>
                            <span id="people">
                                <CiUser />
                            </span>
                            <input type="text" id='recipientName' placeholder='Write first name'/>
                        </div>
                    </div>
        
                    <div>
                        <label htmlFor="recipientLastname">
                        Recipient's Last Name
                        </label>
                        <div className='spanAndInput people'>
                            <span id="people">
                                <CiUser />
                            </span>
                            <input type="text" id='recipientLastname' placeholder='Write last name' />
                        </div>
                    </div>
                </div>
            )
        }

        {
            openAmount && (
                <div className="Amount__9">
                    <div>
                        <label htmlFor="message">
                        Your Message to the Recipient
                        </label>
                        <div>
                            <span className="message"></span>
                            <textarea name="" id="message" cols="30" rows="10" 
                                placeholder='Your message here...'
                            ></textarea>
                        </div>
                    </div>
                    <span className='makeSomeGray'>
                    Please spell check, include how you would like your name(s) signed, mention the amount if desired, and format your message as you would like it delivered.
                    </span>
                </div>
            )
        }


    </div>
  )
}

export default Amount