import React from 'react'
import { MdArrowRightAlt } from "react-icons/md";
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl"

const CurriculumCard = (props) => {
  return (
    <Link className='currCard' style={{
        backgroundImage: `linear-gradient(rgba(34,20,65,0.7) , rgba(34,20,65,0.7)), url("${props.bgImage}")`,
        
      }}>
        <div className='currbg'>
                <div className='curricon' style={{ content: `url(${props.ccicon})` }} ></div>
                <div className='currTitle'>
                    <p>{props.title}</p>
                </div>
                <div className='currDes'>
                    <p>{props.des}</p>
                </div>
                <div className='learnMore hover'>
                        <p>
                        <FormattedMessage id="abu5-link"  />
                          </p>
                        <div className='arrowCircle'>
                    <MdArrowRightAlt className='aciconar'/>
                        </div>
                </div>
        </div>
        
    </Link>
  )
}

export default CurriculumCard
