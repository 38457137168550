import React, { useEffect, useState } from 'react'

const ChangingMode = () => {
    const [darkMode , setDarkMode] = useState(() => {
        const storedDarkMode = localStorage.getItem('darkMode');
        return storedDarkMode === 'enabled';
      });
      const toggleDarkMode = () => {
        setDarkMode(!darkMode)
      }
      useEffect(() => {
        localStorage.setItem('darkMode', darkMode ? 'enabled' : 'disabled');
      }, [darkMode]);
    
      useEffect(() => {
        const body = document.body;
        if (darkMode) {
          body.classList.add('dark');
        } else {
          body.classList.remove('dark');
        }
      },[darkMode])
  return (
    <div className='modeSwitcher' onClick={toggleDarkMode}>
    <div className='sunHolder modeHolder'>
        <div className='sunLight'></div>
    </div>

    <div className='moonHolder modeHolder'>
        <div className='moonLight'></div>
    </div>
</div>
  )
}

export default ChangingMode
