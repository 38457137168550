import React from 'react'
import './OurCenterPlanPrograms.scss'

const OurCenterPlanPrograms = (props) => {
  return (
    <div className='OurCenterPlanPrograms'>
        
        <div className="wwoUp">
            <p>
            Plan 
                <span className="offerRed">
                &nbsp;programs
                </span>
            </p>
        </div>

        <div className="cards">

            <div className="card">
                <div className="topCard ">
                {props.planprogram1}
                </div>
                <div className="bottomCard">

                    <p className='planProgramDescription'>
                        {props.planprogram1Description}
                    </p>

                    <div className="bottomActivites">
                        <h3 className="activites">
                        Activities:
                        </h3>

                        <p>
                            <span className='activites activites1'></span>
                            Enhancing sensory development
                        </p>

                        <p>
                            <span className='activites activites2'></span>
                            Development of motor skills
                        </p>

                        <p>
                            <span className='activites activites3'></span>
                            Music and movement
                        </p>

                        <p>
                            <span className='activites activites4'></span>
                            Storytelling, puppet shows, and sing-alongs
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="topCard purple__topCard">
                    {props.planprogram2}
                </div>
                <div className="bottomCard">

                    <p className='planProgramDescription'>
                        {props.planprogram2Description}
                    </p>

                    <div className="bottomActivites">
                        <h3 className="activites">
                        Activities:
                        </h3>

                        <p>
                            <span className='activites activites5'></span>
                            Hands-on learning
                        </p>

                        <p>
                            <span className='activites activites6'></span>
                            Alphabet games, story time and reading
                        </p>

                        <p>
                            <span className='activites activites7'></span>
                            Group projects and role-play games
                        </p>

                        <p>
                            <span className='activites activites8'></span>
                            Art projects and singing classes
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="topCard green__topCard">
                    {props.planprogram3}
                </div>
                <div className="bottomCard">

                    <p className='planProgramDescription'>
                        {props.planprogram3Description}
                    </p>

                    <div className="bottomActivites">
                        <h3 className="activites">
                        Activities:
                        </h3>

                        <p>
                            <span className='activites activites1'></span>
                            Preparing for school activities
                        </p>

                        <p>
                            <span className='activites activites2'></span>
                            Problem-solving skills, puzzle games
                        </p>

                        <p>
                            <span className='activites activites3'></span>
                            Communication skills, interaction sessions
                        </p>

                        <p>
                            <span className='activites activites4'></span>
                            Sports, yoga and dance parties
                        </p>
                    </div>
                </div>
            </div>

        </div>

    </div>
  )
}

export default OurCenterPlanPrograms