import React, { useEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel'
import WhatPeopleSayCard from './WhatPeopleSayCard'

const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 2 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 3 },
    { width: 1750, itemsToShow: 3 }
  ]
const WhatPeopleSayTabPanel = ({ tabName  }) => {
  const [specificData, setSpecificData] = useState([]);
  const [data,setData] = useState([])

  const fetchData = async () => {
    try {
      const storedLocale = localStorage.getItem('locale');
      const supportedLocales = ['en-GB', 'sq-AL'];
      const defaultLocale = 'en-GB';

      const locale = supportedLocales.includes(storedLocale) ? storedLocale : defaultLocale;
      const api = `/API/WhatPeopleSay-${locale}.json`;

      // console.log('Selected Locale:', locale);
      // console.log('API Endpoint:', api);

      const response = await fetch(api);
      const data = await response.json();

      const filteredData = tabName === "All" ? data : data.filter((item) => item.role === tabName);

      setSpecificData(filteredData);
      setData(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [localStorage.getItem('language'), tabName]);


      const carouselRef = React.useRef(null);
      const onNextStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo(0);
        }
      };
      const onPrevStart = (currentItem, nextItem) => {
        if (currentItem.index === nextItem.index) {
          carouselRef.current.goTo("single-slide".length);
        }
      };
  return (
    <div className='centerTabPanel'>
      <Carousel
       itemPadding={[30, 20]}
       className="centerCarousel"
       breakPoints={breakPoints}
       ref={carouselRef}
       onPrevStart={onPrevStart}
       onNextStart={onNextStart}
       disableArrowsOnEnd={false}
      >
        
        {/* {
          data.map((s) => {
            return(
              <WhatPeopleSayCard
                circle={s.circle}
                anotherPerson={s.anotherPerson}
                braces={s.braces}
                cardDes={s.cardDes}
                role={s.role}
                name={s.name}
                star={s.star}
              />
            )
          })
        } */}


{specificData.map((s) => (
          <WhatPeopleSayCard
            key={s.id}
            circle={s.circle}
            anotherPerson={s.anotherPerson}
            braces={s.braces}
            cardDes={s.cardDes}
            role={s.role}
            name={s.name}
            star={s.star}
          />
        ))}

      </Carousel>
    </div>
  )
}

export default WhatPeopleSayTabPanel
