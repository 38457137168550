import React from 'react'
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl"

const CoursesCard = (props) => {

  return (
    <Link className='centerCard'
        to={
            { 
            pathname: `/coursesDetails/${props.id}/${props.childish.replace(/ /g, "_").replace(/\//g, "_")}`,
            state: { coursesDetails: props } 
            }
        }
    >
        <div className='kinderGartenOC' style={{ backgroundColor : props.bgColor  }}>
            <p>{props.childish}</p>
        </div>
        <div className='centerCardUp'>
            <div className='centerCardUpImg' style={{ content: `url(${props.upperImage})` }}></div>
        </div>
        <div className='centerCardDown'>
            <div className='centerCardDown-up'>
               <div className='centerCardDown-upfirst-textOC'>
                    <p>{props.ccardText}</p>
               </div>
            </div>

            <div className='centerCardDown-second'>
                <div className='centerCardDown-second-content'>
                    <div className='policyIcon' style={{ content: `url(${props.policy})` }} ></div>
                    <div className='centerCardDown-second-content-textual'>
                        <p className='contextCard'>
                        <FormattedMessage id="instructor"  />
                            </p>
                        <p className='ccardAge'>Ana Smith</p>
                    </div>
                </div>
                <div className='centerCardDown-second-content'>
                    <div className='locationIcon' style={{ content: `url(${props.locationIcon})` }}></div>
                    <div className='centerCardDown-second-content-textual'>
                        <p className='contextCard'>
                        <FormattedMessage id="schedule"  />
                            </p>
                        <p className='ccardAge'>16:00 - 18:00</p>
                    </div>
                </div>
            </div>

            <div className='centerCardDown-buttons'>
                <div className='leftButtonCardDown button ocButton'>
                    <Link>
                    <FormattedMessage id="enrollLink"  />
                    </Link>
                </div>
                <div className='emptyButton'>
                    <Link><span className='dollar'>$</span>{props.paid}</Link>
                </div>
                
            </div>

        </div>
    </Link>
  )
}

export default CoursesCard