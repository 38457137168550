import React from 'react'
import './KosovaKidsTab.scss'
import NumbersRising from '../NumbersRising/NumbersRising'

const KosovaKidsTab = () => {
  return (
    <div className='KosovaKidsTab'>
        <div className="KosovaKidsTab__1">
            <div className="aboutUsHeader">
                <p>
                KosovaKids 
                <span className='yellowA'>
                    Center
                </span>
                </p>
            </div>

            <div className="aboutUsText">
                <p>
                Our comprehensive range of tools is here to help you with all your child care needs.
                </p>
            </div>
        </div>

        <div className="KosovaKidsTab__2">
            <div className="KosovaKidsTab__2__filter"></div>
            <div className="insideBorder">
                <div className="recordIcon"></div>
            </div>
        </div>

        <div className="KosovaKidsTab__3">
            <NumbersRising />
        </div>
        

    </div>
  )
}

export default KosovaKidsTab