import React from 'react'
import './YouCanLearn.scss';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl"

const YouCanLearn = () => {
  return (
    <div className='youCanLearn'>
        <div className='youCanLearnLeft'>
            <div className='yycircle1 yycircle'></div>
            <div className='yycircle2 yycircle'></div>
        </div>
        <div className='youCanLearnMiddle'>
            <p className='yyyheader'><FormattedMessage id="ycl1"/></p>
            <div className='yyycmText'>
                <p><FormattedMessage id="ycl2"/></p>
            </div>
            <div className='currButton button'>
                <Link><FormattedMessage id="ycl3"/></Link>
            </div>
        </div>
        <div className='youCanLearnRight'>
             <div className='yycircle3 yycircle'></div>
            <div className='yycircle4 yycircle'></div>
        </div>
    </div>
  )
}

export default YouCanLearn
