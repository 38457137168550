import React from 'react'
import './DayCareCard3.scss'
import { Link } from 'react-router-dom'

const DayCareCard3 = ({ bgImage, icon1, icon2, icon3, title, text }) => {
    const bgImageDiv = {
        backgroundImage: `url(${bgImage})`
    }

    const icon1Div = {
        content: `url(${icon1})`
    }

    const icon2Div = {
        content: `url(${icon2})`
    }

    const icon3Div = {
        content: `url(${icon3})`
    }



  return (
    <div className='DayCareCard3' style={bgImageDiv}>
        <div className="DayCareCard3__filter"></div>
        <div className="DayCareCard3__1" >
            <div className="DayCareCard3__icon1" style={icon1Div}></div>
            <div className="DayCareCard3__icon2" style={icon2Div}></div>
            <div className="DayCareCard3__icon3" style={icon3Div}></div>
        </div>

        <div className="DayCareCard3__2">
            <h1 className="DayCareCard3Title">
            Enroll in our Kindergarten
            </h1>

            <p className="DayCareCard3Text">
            Start your child's educational journey with KosovaKids' Kindergarten, where every day is a step towards growth, discovery, and joy. Contact us to learn more about our programs and how we can support your child's early learning adventure.
            </p>

            <div className="DayCareCard3__buttons">
                <Link className='fullBg'>
                Explore our centers
                </Link>

                <Link className='allYellow'>
                Contact us
                </Link>
            </div>
        </div>


    </div>
  )
}

export default DayCareCard3