import React from "react";
import "./OurHistory.scss";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import { Link } from "react-router-dom";

const OurHistory = () => {
  return (
    <div className="OurHistory">
      <Navbar />

      <div className="OurHistory__inside">
        <div className="OurHistory__1">
          <div className="OurHistory__1__filter"></div>
          <h1>Our History</h1>
          <div className="OurHistory__1__rightImage"></div>
        </div>

        <div className="OurHistory__padding">
          <div className="OurHistory__2">
            <div className="left">
              <div className="imageHistory image2">
                <div className="s1magnet"></div>
                <div className="s1wave"></div>
                <div className="s1star"></div>
              </div>
            </div>

            <div className="OurHistoryText right">
              <h1 className="title">How we stared KosovaKids?</h1>

              <p className="pText">
                KosovaKids was born from a vision to revolutionize the
                educational landscape for the children of Kosovo. Our founders,
                a group of passionate educators, recognized the immense
                potential within every child to create, innovate, and lead.
                Motivated by the desire to unlock this potential, we embarked on
                a mission to provide accessible, high-quality education that
                nurtures creativity, critical thinking, and a lifelong love for
                learning. We believed that by equipping children with diverse
                skills from an early age, we could lay the foundation for a
                brighter, more innovative future for Kosovo and beyond.
              </p>
            </div>
          </div>

          <div className="OurHistory__3">
            <div className="OurHistoryText left">
              <h1 className="title">We started offering courses for kids</h1>

              <p className="pText">
                Our journey began with an ambitious goal: to offer a wide range
                of courses tailored to children's varied interests and learning
                styles. We introduced programming courses, language learning,
                mathematics, and science, believing that a multidisciplinary
                approach is key to holistic child development. Recognizing the
                importance of creativity in learning, we soon expanded our
                curriculum to include design, Scratch programming, drawing,
                arts, and more.
              </p>

              <Link className="ourHistoryLink">Explore our courses</Link>
            </div>

            <div className="OurHistoryText right">
              <div className="imageHistory image3">
                <div className="s2circle"></div>
                <div className="s2circles"></div>
                <div className="s2star"></div>
              </div>
            </div>
          </div>

          <div className="OurHistory__4">
            <div className="left">
              <div className="imageHistory image4">
                <div className="s3magnet"></div>
                <div className="s3star"></div>
              </div>
            </div>

            <div className="OurHistoryText right">
              <h1 className="title">Education, reimagined!</h1>

              <p className="pText">
                At KosovaKids, we believe that education should be a journey of
                discovery, filled with joy, creativity, and exploration. Our
                innovative approach to learning reimagines traditional
                education, making it more engaging, interactive, and tailored to
                the individual needs of each child. We strive to create an
                environment where children feel valued, inspired, and empowered
                to reach their full potential. Through our courses, we not only
                impart knowledge but also cultivate essential life skills, such
                as critical thinking, problem-solving, and collaboration,
                preparing our students for the challenges and opportunities of
                the future.
              </p>
            </div>
          </div>

          <div className="OurHistory__5">
            <div className="OurHistoryText left">
              <h1 className="title">Our centers</h1>

              <p className="pText">
                Building on the success of our courses, KosovaKids embarked on
                an exciting new chapter by opening kindergarten centers across
                Kosovo. These centers, along with our daycare, weekend stay, and
                night stay facilities, reflect our commitment to providing
                comprehensive, high-quality care and education for children.
                Each center is designed to be a safe, nurturing space where
                children can learn, play, and grow under the guidance of our
                dedicated educators and caregivers. By expanding our physical
                presence, we aim to reach more families and make a positive
                impact in communities across the country.
              </p>

              <Link className="ourHistoryLink">Explore our centers</Link>
            </div>

            <div className="OurHistoryText right">
              <div className="imageHistory image5">
                <div className="s4circle"></div>
                <div className="s4magnet"></div>
                <div className="s4wave"></div>
              </div>
            </div>
          </div>

          <div className="OurHistory__6">
            <div className="left">
              <div className="imageHistory image6">
                <div className="s5circle"></div>
                <div className="s5star"></div>
              </div>
            </div>

            <div className="OurHistoryText right">
              <h1 className="title">Growing more</h1>

              <p className="pText">
                As KosovaKids continues to grow, our commitment to reimagining
                education remains unwavering. We are constantly exploring new
                ways to enrich our curriculum, integrate innovative teaching
                methods, and expand our reach to ensure that every child in
                Kosovo has the opportunity to benefit from our programs. Our
                journey is fueled by the success stories of our students, the
                dedication of our team, and the support of our community.
                Looking ahead, we are excited to embark on new initiatives,
                forge partnerships, and continue making a difference in the
                lives of children and families in Kosovo.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OurHistory;
